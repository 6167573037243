<template>
  <div class="wrapper d-flex flex-column min-vh-100">
    <div class="content-page py-3">
      <CContainer class="p-5">
        <div class="sub-container">
          <router-view />
        </div>
      </CContainer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentLayout',
}
</script>
