import axiosAPI from '@/api/axios'

export default {
  async fetchPingtree(params) {
    return await axiosAPI.get('/api/pingtree', {
      params: params,
    })
  },

  async syncPingtree(payload) {
    return await axiosAPI.post('/api/pingtree/sync', payload)
  },
}
