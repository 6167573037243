import axiosAPI from '@/api/axios'

export default {
  async fetchOffers() {
    return await axiosAPI.get('/api/offers')
  },

  async addOffer(payload) {
    return await axiosAPI.post('/api/offers/', payload)
  },

  async updateOffer(id, payload) {
    return await axiosAPI.post(`/api/offers/${id}`, payload)
  },

  async deleteOffer(id) {
    return await axiosAPI.delete(`/api/offers/${id}`)
  },

  async fetchOfferParams(id) {
    return await axiosAPI.get(`/api/offers/${id}/params`)
  },
}
