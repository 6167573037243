import offers from '@/api/offers'
import helpers from '@/utils/helpers'

const state = {
  offers: [],
  loading: false,
  params_loading: false,
  error: null,
  params: [],
}

const mutations = {
  SET_OFFERS(state, offers) {
    state.offers = offers
  },
  SET_PARAMS(state, params) {
    state.params = params
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_PARAMS_LOADING(state, loading) {
    state.params_loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  INSERT_OFFER(state, offer) {
    state.offers = [].concat(offer, state.offers)
  },
  UPDATE_OFFER(state, offer) {
    const index = state.offers.findIndex((item) => item.id === offer.id)
    state.offers[index].name = offer.name
    state.offers[index].landing_page_url = offer.landing_page_url
    state.offers[index].overwrite_price = offer.overwrite_price
  },
  REMOVE_OFFER(state, id) {
    const index = state.offers.findIndex((item) => item.id === id)
    if (~index) state.offers.splice(index, 1)
  },
}

const actions = {
  async fetchOffers({ commit }) {
    commit('SET_LOADING', true)
    return await offers
      .fetchOffers()
      .then((response) => {
        commit('SET_OFFERS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async addOffer({ commit }, payload) {
    return await offers
      .addOffer(payload)
      .then((response) => {
        commit('INSERT_OFFER', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async updateOffer({ commit }, { id, payload }) {
    return await offers
      .updateOffer(id, payload)
      .then((response) => {
        commit('UPDATE_OFFER', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async deleteOffer({ commit }, id) {
    return await offers
      .deleteOffer(id)
      .then(() => {
        commit('REMOVE_OFFER', id)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async fetchOfferParams({ commit }, id) {
    commit('SET_PARAMS_LOADING', true)
    commit('SET_PARAMS', [])
    return await offers
      .fetchOfferParams(id)
      .then((response) => {
        commit('SET_PARAMS', response.data.data)
        commit('SET_PARAMS_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_PARAMS_LOADING', false)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
