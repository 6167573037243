<template>
  <CSidebarNav>
    <SkiptrazeSidebarItem :list="mainList || main" />
    <li class="nav-title">{{ settings.title }}</li>
    <SkiptrazeSidebarItem :list="settingsList || settings" />
  </CSidebarNav>
</template>

<script>
import routerSkiptraze from '@/skiptraze/router'
import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
import SkiptrazeSidebarItem from '@/skiptraze/views/sidebar/MenuItem.vue'
import { main, settings } from '@/skiptraze/views/assets/sidebar'

export default {
  name: 'SkiptrazeSidebar',

  components: {
    SkiptrazeSidebarItem,
  },

  setup() {
    const route = useRoute()
    const mainList = ref()
    const settingsList = ref()

    const normalizePath = (path) => {
      return decodeURI(path)
        .replace(/#.*$/, '')
        .replace(/(index)?\.(html)$/, '')
    }

    const isItemActive = (item) => {
      if (item.to === undefined) {
        return false
      }

      if (route.hash === item.to) {
        return true
      }

      const currentPath = normalizePath(route.path)
      const targetPath = normalizePath(item.to)

      return currentPath === targetPath
    }

    const getList = (items) => {
      return items?.map((item) => {
        return {
          ...item,
          isActive: isItemActive(item),
        }
      })
    }

    routerSkiptraze.afterEach(() => {
      mainList.value = getList(main)
      settingsList.value = getList(settings)
    })

    onMounted(() => {
      mainList.value = getList(main)
      settingsList.value = getList(settings)
    })

    return {
      mainList,
      settingsList,
    }
  },

  data() {
    return {
      main: main,
      settings: {
        title: 'Settings',
        items: settings,
      },
    }
  },
}
</script>
