import axiosAPI from '@/api/axios'

export default {
  async fetchCounter() {
    return await axiosAPI.get('/api/statistics/counter')
  },

  async fetchLeadStatistics(payload) {
    return await axiosAPI.get('/api/statistics/leads', {
      params: {
        start: payload.start,
        end: payload.end,
        group_by: payload.group_by,
        duplicity: payload.duplicity,
        partner: payload.partner,
        sub_id: payload.sub_id,
      },
    })
  },

  async fetchRevenueLeads(payload) {
    return await axiosAPI.get('/api/statistics/revenue/leads', {
      params: {
        page: payload?.page,
        perPage: payload?.perPage,
        start: payload.start,
        end: payload.end,
        lender: payload.lender,
        partner: payload.partner,
      },
    })
  },

  async fetchDailyLeads(payload) {
    return await axiosAPI.get('/api/statistics/leads/daily', {
      params: {
        start: payload.start,
        end: payload.end,
      },
    })
  },

  async fetchLeadAcceptance(payload) {
    return await axiosAPI.get('/api/statistics/leads/acceptance', {
      params: {
        start: payload.start,
        end: payload.end,
        sources: payload?.sources || [],
      },
    })
  },

  async fetchTimeMonitoring() {
    return await axiosAPI.get('/api/statistics/monitoring/time')
  },

  async fetchLeadsMonitoring(payload) {
    return await axiosAPI.get('/api/statistics/monitoring/leads', {
      params: {
        period_type: payload.period_type,
        preset: payload.preset,
        range: payload.range,
        sources: payload?.sources || [],
      },
    })
  },

  async fetchRevenueMonitoring(payload) {
    return await axiosAPI.get('/api/statistics/monitoring/revenue', {
      params: {
        period_type: payload.period_type,
        preset: payload.preset,
        range: payload.range,
        types: payload?.types || [],
        sources: payload?.sources || [],
      },
    })
  },

  async fetchRplMonitoring(payload) {
    return await axiosAPI.get('/api/statistics/monitoring/rpl', {
      params: {
        period_type: payload.period_type,
        preset: payload.preset,
        range: payload.range,
        views: payload?.views || [],
        sources: payload?.sources || [],
        barChart: payload?.barChart || false,
      },
    })
  },

  async fetchRevenue(payload) {
    return await axiosAPI.get('/api/statistics/revenue', {
      params: {
        start: payload.start,
        end: payload.end,
        lender: payload.lender,
        partner: payload.partner,
        show_daily: payload?.show_daily,
      },
    })
  },

  async fetchStatus(payload) {
    return await axiosAPI.get('/api/statistics/status', {
      params: {
        start: payload.start,
        end: payload.end,
        partner: payload.partner,
        status: payload.status,
      },
    })
  },

  async fetchApiStatus(payload) {
    return await axiosAPI.get('/api/statistics/status/lenders', {
      params: {
        start: payload.start,
        end: payload.end,
        lenders: payload.lenders,
      },
    })
  },

  async fetchTrackVisits(payload) {
    return await axiosAPI.get('/api/statistics/track-visits', {
      params: {
        page: payload?.page,
        perPage: payload?.perPage,
        start: payload.start,
        end: payload.end,
        show_unique: payload.show_unique,
      },
    })
  },
}
