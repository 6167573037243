<template>
  <CTableDataCell
    class="text-center py-5 text-danger"
    scope="row"
    :colspan="colspan"
  >
    <Empty />
  </CTableDataCell>
</template>

<script>
import Empty from '@/components/others/Empty.vue'

export default {
  name: 'BodyRowEmpty',

  components: {
    Empty,
  },

  props: {
    colspan: {
      type: [String, Number],
      required: true,
    },
  },
}
</script>
