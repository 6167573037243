import axiosAPI from '@/api/axios'

export default {
  async fetchHistoryCounter() {
    return await axiosAPI.get('/api/logs/history/counter')
  },

  async fetchHistory(params) {
    return await axiosAPI.get('/api/logs/history', {
      params: {
        page: params?.page,
        perPage: params?.perPage,
      },
    })
  },

  async fetchClicks(params) {
    return await axiosAPI.get('/api/logs/clicks', {
      params: {
        page: params?.page,
        perPage: params?.perPage,
        start: params?.start,
        end: params?.end,
        uuid: params?.uuid,
        status: params?.status,
        offer_id: params?.offer_id,
        affiliate_id: params?.affiliate_id,
      },
    })
  },

  async fetchConversions(params) {
    return await axiosAPI.get('/api/logs/conversions', {
      params: {
        page: params?.page,
        perPage: params?.perPage,
        start: params?.start,
        end: params?.end,
      },
    })
  },

  async fetchApiRequests(params) {
    return await axiosAPI.get('/api/logs/api-requests', {
      params: {
        page: params?.page,
        perPage: params?.perPage,
        start: params?.start,
        end: params?.end,
        lead_id: params?.lead_id,
        partner: params?.partner,
        status: params?.status,
      },
    })
  },
}
