<template>
  <CIcon icon="cil-ban" class="empty" />
  <span class="empty-text">{{ empty }}</span>
</template>

<script>
export default {
  name: 'Empty',

  data() {
    return {
      empty: 'Nothing to show',
    }
  },
}
</script>
