export const main = [
  {
    name: 'Dashboard',
    icon: 'cil-speedometer',
    isActive: true,
    to: '/dashboard',
  },
  {
    name: 'Requests',
    icon: 'cil-gem',
    isActive: false,
    to: '/requests',
  },
  {
    name: 'API Integration',
    icon: 'cil-notes',
    isActive: false,
    to: '/api',
  },
]

export const settings = [
  {
    name: 'Logout',
    icon: 'cil-account-logout',
    isActive: false,
    to: '/logout',
  },
]
