<template>
  <div id="casheddy-app" class="casheddy">
    <SidebarLayout />
    <ContentLayout />
  </div>
</template>

<script>
import SidebarLayout from '@/skiptraze/views/integration/sidebar/Layout.vue'
import ContentLayout from '@/skiptraze/views/integration/content/Layout.vue'

export default {
  name: 'SkiptrazeIntegration',

  components: {
    SidebarLayout,
    ContentLayout,
  },
}
</script>
