import axiosAPI from '@/api/axios'

export default {
  async login(payload) {
    await axiosAPI.get('/sanctum/csrf-cookie')
    return axiosAPI.post('/publisher/login', payload)
  },

  logout() {
    return axiosAPI.post('/publisher/logout')
  },

  async fetchDashboardCounter(payload) {
    return axiosAPI.get('/publisher/dashboard/counter', { params: payload })
  },

  async fetchDashboardHistory(payload) {
    return axiosAPI.get('/publisher/dashboard/history', { params: payload })
  },

  async fetchLeads(payload) {
    return axiosAPI.get('/publisher/leads', {
      params: {
        page: payload?.page,
        perPage: payload?.perPage,
        start: payload.start,
        end: payload.end,
        last_name: payload.last_name,
        email: payload.email,
        mobile: payload.mobile,
        show_redirected_only: payload.show_redirected_only,
      },
    })
  },

  async fetchStatistics(payload) {
    return axiosAPI.get('/publisher/statistics', {
      params: {
        start: payload.start,
        end: payload.end,
        group_by: payload.group_by,
        sub_id: payload.sub_id,
      },
    })
  },

  async fetchNotificationSettings() {
    return axiosAPI.get('/publisher/settings/notifications')
  },

  async updateNotificationSettings(payload) {
    return axiosAPI.post('/publisher/settings/notifications', payload)
  },
}
