import { createRouter, createWebHashHistory } from 'vue-router'
import storeSkiptraze from '@/skiptraze/store'

import SkiptrazeLayout from '@/skiptraze/views/Layout.vue'
import SkipIntegration from '@/skiptraze/views/integration/Integration.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/login',
    meta: { guestOnly: true },
  },
  {
    path: '/login',
    name: 'Login',
    meta: { guestOnly: true },
    component: () =>
      import(/* webpackChunkName: "login" */ '@/skiptraze/views/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () =>
      import(/* webpackChunkName: "logout" */ '@/skiptraze/views/Logout.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: SkiptrazeLayout,
    meta: { authOnly: true },
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: '',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/skiptraze/views/Dashboard.vue'
          ),
      },
    ],
  },
  {
    path: '/requests',
    name: 'Requests',
    component: SkiptrazeLayout,
    meta: { authOnly: true },
    redirect: '/requests',
    children: [
      {
        path: '/requests',
        name: '',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/skiptraze/views/Requests.vue'
          ),
      },
    ],
  },
  {
    path: '/api',
    name: 'API Integration',
    component: SkipIntegration,
    meta: { authOnly: true },
    redirect: '/api/authorization',
    children: [
      {
        path: '/api/authorization',
        name: '',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/skiptraze/views/integration/content/Authorization.vue'
          ),
      },
      {
        path: '/api/endpoints',
        name: '',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/skiptraze/views/integration/Integration.vue'
          ),
      },
      {
        path: '/api/endpoints/get-request',
        name: '',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/skiptraze/views/integration/Integration.vue'
          ),
      },
      {
        path: '/api/endpoints/post-requests',
        name: '',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/skiptraze/views/integration/Integration.vue'
          ),
      },
      {
        path: '/errors',
        name: '',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/skiptraze/views/integration/Integration.vue'
          ),
      },
    ],
  },
  {
    path: '/500',
    name: 'Server Error',
    component: () =>
      import(/* webpackChunkName: "500" */ '@/views/errors/500.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page Not Found',
    component: () =>
      import(/* webpackChunkName: "404" */ '@/views/errors/404.vue'),
  },
]

const routerSkiptraze = createRouter({
  history: createWebHashHistory('/skiptraze/'),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

routerSkiptraze.beforeEach((to, from, next) => {
  // reset all forms
  storeSkiptraze.dispatch('forms/updateForm', {})

  if (to.matched.some((record) => record.meta.authOnly)) {
    // if user is not logged in
    if (
      !storeSkiptraze.state.skiptraze?.loggedIn ||
      !storeSkiptraze.state.skiptraze?.user?.is_skiptraze
    ) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
      return
    }
  }

  // guest route
  if (to.matched.some((record) => record.meta.guestOnly)) {
    // if user is authenticated
    if (
      storeSkiptraze.state.skiptraze?.loggedIn &&
      storeSkiptraze.state.skiptraze?.user?.is_skiptraze
    ) {
      next({
        path: '/dashboard',
        query: { redirect: to.fullPath },
      })
      return
    }
  }

  next()
  return
})

export default routerSkiptraze
