<template>
  <CTable :align="align" responsive hover :class="className" bordered>
    <CTableCaption v-if="captions !== ''">{{ captions }}</CTableCaption>
    <HeaderRowWithProps
      :columns="columns"
      :extras="extras"
      :isEmpty="isEmpty"
      :with-sorter="withSorter"
      v-if="headerWithProps"
      @sort:column="(value) => $emit('sort:column', value)"
    />
    <HeaderRow
      :columns="columns"
      :with-sorter="withSorter"
      @sort:column="(value) => $emit('sort:column', value)"
      v-else
    />
    <TableSkeleton
      :columns="headerWithProps ? spans || columns : columns"
      :rows="rows"
      v-if="isLoading"
    />
    <CTableBody v-else>
      <CTableRow v-if="isEmpty">
        <BodyRowEmpty :colspan="Object.keys(columns).length" />
      </CTableRow>
      <slot name="list" v-else></slot>
    </CTableBody>
    <CTableFoot>
      <slot name="footer"></slot>
    </CTableFoot>
  </CTable>
</template>

<script>
import HeaderRow from '@/components/tables/common/HeaderRow.vue'
import HeaderRowWithProps from '@/components/tables/common/HeaderRowWithProps.vue'
import BodyRowEmpty from '@/components/tables/common/BodyRowEmpty.vue'
import TableSkeleton from '@/components/tables/common/Skeleton.vue'

export default {
  name: 'TableLayout',

  components: {
    HeaderRow,
    HeaderRowWithProps,
    BodyRowEmpty,
    TableSkeleton,
  },

  props: {
    withSorter: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    columns: {
      required: true,
    },
    extras: {
      required: false,
    },
    spans: {
      type: Number,
      required: false,
    },
    isEmpty: {
      type: Boolean,
      required: true,
    },
    captions: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    headerWithProps: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 15,
    },
  },

  data() {
    return {
      align: 'middle',
      footer: ['Footer', 'Footer', 'Footer'],
    }
  },

  emits: ['sort:column'],
}
</script>
