import axiosAPI from '@/api/axios'

export default {
  async fetchDomains() {
    return await axiosAPI.get('/api/domains')
  },

  async addDomain(payload) {
    return await axiosAPI.post('/api/domains', payload)
  },

  async updateDomain(id, payload) {
    return await axiosAPI.post(`/api/domains/${id}`, payload)
  },

  async regenerateApiKey(id) {
    return await axiosAPI.post(`/api/domains/${id}/regenerate/key`)
  },

  async updateStatus(id) {
    return await axiosAPI.post(`/api/domains/${id}/status`)
  },

  async deleteDomain(id) {
    return await axiosAPI.delete(`/api/domains/${id}`)
  },
}
