import affiliates from '@/api/affiliates'
import helpers from '@/utils/helpers'

const state = {
  affiliates: [],
  loading: false,
  error: null,
  revenues: [],
  reports: [],
  reportId: null,
}

const mutations = {
  SET_AFFILIATES(state, affiliates) {
    state.affiliates = affiliates
  },
  SET_REVENUE_REPORTS(state, revenues) {
    state.revenues = revenues
  },
  SET_GROUP_REPORTS(state, reports) {
    state.reports = reports
  },
  SET_NEW_REPORT_ID(state, reportId) {
    state.reportId = reportId
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  INSERT_AFFILIATE(state, affiliate) {
    state.affiliates = [].concat(affiliate, state.affiliates)
  },
  INSERT_GROUP_REPORT(state, report) {
    state.reports = [].concat(report, state.reports)
  },
  UPDATE_AFFILIATE(state, affiliate) {
    const index = state.affiliates.findIndex((item) => item.id === affiliate.id)
    state.affiliates[index] = affiliate
  },
  REMOVE_AFFILIATE(state, id) {
    const index = state.affiliates.findIndex((item) => item.id === id)
    if (~index) state.affiliates.splice(index, 1)
  },
}

const actions = {
  updateReports({ commit }, data) {
    commit('INSERT_GROUP_REPORT', data)
    commit('SET_NEW_REPORT_ID', data.id)
  },

  updateReportId({ commit }, value) {
    commit('SET_NEW_REPORT_ID', value)
  },

  async fetchAffiliates({ commit }) {
    commit('SET_LOADING', true)
    return await affiliates
      .fetchAffiliates()
      .then((response) => {
        commit('SET_AFFILIATES', response.data.data)
        commit('SET_REVENUE_REPORTS', response.data.revenue_reports)
        commit('SET_GROUP_REPORTS', response.data.custom_reports)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async addAffiliate({ commit }, payload) {
    return await affiliates
      .addAffiliate(payload)
      .then((response) => {
        commit('INSERT_AFFILIATE', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async updateAffiliate({ commit }, { id, payload }) {
    return await affiliates
      .updateAffiliate(id, payload)
      .then((response) => {
        commit('UPDATE_AFFILIATE', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async deleteAffiliate({ commit }, id) {
    return await affiliates
      .deleteAffiliate(id)
      .then(() => {
        commit('REMOVE_AFFILIATE', id)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
