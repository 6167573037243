<template>
  <CNavLink @click="(event) => handleShowMenu(event, to)">{{ name }}</CNavLink>
</template>

<script>
import routerSkiptraze from '@/skiptraze/router'
import storeSkiptraze from '@/skiptraze/store'

export default {
  name: 'SidebarMenuItemLink',

  props: {
    name: {
      type: String,
      required: true,
    },
    to: String,
    onShowMenu: Function,
  },

  computed: {
    partner() {
      return storeSkiptraze.state.documentation.partner
    },
  },

  methods: {
    handleShowMenu(event, dest) {
      this.onShowMenu(event)

      const hash = this.partner?.hash

      if (dest && dest !== '') {
        routerSkiptraze.push({ path: dest, query: { partner_hash: hash } })
      }
    },
  },
}
</script>
