<template>
  <CRow :class="className">
    <CFormLabel :for="id" class="col-form-label" :class="selectorClasses">
      {{ name }}<span class="text-danger" v-if="required">*</span>
    </CFormLabel>
    <div :class="fromModal ? 'col-sm-9' : 'col-sm-10'">
      <CFormSelect
        :id="id"
        :aria-label="ariaLabel"
        :feedbackInvalid="feedbackInvalid"
        :required="required"
        :value="modelValue"
        @change="$emit('update:modelValue', $event.target.value)"
        :disabled="disabled"
        :invalid="isInvalid"
      >
        <option selected="" :disabled="!withDefault" value="">
          {{ defaultOption }}
        </option>
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.value"
        >
          {{ option.label }}
        </option>
        <option
          class="form-select-action"
          v-if="actionText !== ''"
          :value="actionValue"
        >
          {{ actionText }}
        </option>
      </CFormSelect>
    </div>
  </CRow>
</template>

<script>
import useBreakpoints from 'vue-next-breakpoints'

export default {
  name: 'FormSimpleSelector',

  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    ariaLabel: {
      type: String,
      default: 'text',
    },
    options: {
      type: Object,
      required: true,
    },
    feedbackInvalid: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: 'mb-3',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    fromModal: {
      type: Boolean,
      default: false,
    },
    withDefault: {
      type: Boolean,
      default: false,
    },
    actionText: {
      type: String,
      default: '',
    },
    actionValue: {
      type: [String, Number],
      default: '',
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const breakpoints = useBreakpoints({
      mobile: 991,
      desktop: [992],
    })

    return {
      breakpoints,
    }
  },

  computed: {
    selectorClasses() {
      let append = this.name == '' || !this.name ? 'h-0' : ''
      if (this.breakpoints.mobile.matches && append == 'h-0') {
        append = 'd-none'
      }

      const colClass = this.fromModal ? 'col-sm-3' : 'col-sm-2'
      return colClass + ' ' + append
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      defaultOption: 'Choose an option',
    }
  },
}
</script>
