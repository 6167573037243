<template>
  <AppToast />
  <AppSidebar :onClick="onHandleClick">
    <template #app-sidebar>
      <AppSidebarNav />
    </template>
  </AppSidebar>
  <div class="wrapper d-flex flex-column min-vh-100 bg-light">
    <AppHeader />
    <div class="body flex-grow-1 px-3">
      <CContainer lg :key="componentKey">
        <router-view />
      </CContainer>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import router from '@/router'
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/app/AppFooter.vue'
import AppHeader from '@/components/app/AppHeader.vue'
import AppSidebar from '@/components/app/AppSidebar.vue'
import AppToast from '@/components/app/AppToast.vue'
import { AppSidebarNav } from '@/utils/sidebar'

export default {
  name: 'AdminLayout',

  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    AppToast,
    AppSidebarNav,
    CContainer,
  },

  setup() {
    const defaultCountry = ref('spain')

    const componentKey = computed(() => {
      return store.state.countries.renderKey
    })

    const handleChangeConnection = (value) => {
      store.dispatch('countries/changeConnection', { country: value })
    }

    onMounted(() => {
      const connection = localStorage.getItem('connection')

      if (connection !== defaultCountry.value) {
        defaultCountry.value = connection
        handleChangeConnection(connection)
      }
    })

    return { componentKey }
  },

  methods: {
    onHandleClick() {
      router.push('/dashboard')
    },
  },
}
</script>
