import partners from '@/api/partners'
import helpers from '@/utils/helpers'

const state = {
  partners: [],
  loading: false,
  error: null,
}

const mutations = {
  SET_PARTNERS(state, partners) {
    state.partners = partners
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  INSERT_PARTNER(state, partner) {
    state.partners = [].concat(partner, state.partners)
  },
  UPDATE_PARTNER(state, partner) {
    const index = state.partners.findIndex((item) => item.id === partner.id)
    state.partners[index] = partner
  },
  UPDATE_PARTNER_SETTINGS(state, partner) {
    const index = state.partners.findIndex((item) => item.id === partner.id)
    state.partners[index] = partner
  },
  UPDATE_PARTNER_STATUS(state, partner) {
    const index = state.partners.findIndex((item) => item.id === partner.id)
    state.partners[index].active = partner.active
  },
  REMOVE_PARTNER(state, id) {
    const index = state.partners.findIndex((item) => item.id === id)
    if (~index) state.partners.splice(index, 1)
  },
}

const actions = {
  async fetchPartners({ commit }) {
    commit('SET_LOADING', true)
    return await partners
      .fetchPartners()
      .then((response) => {
        commit('SET_PARTNERS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async addPartner({ commit }, payload) {
    return await partners
      .addPartner(payload)
      .then((response) => {
        commit('INSERT_PARTNER', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async updatePartner({ commit }, { id, payload }) {
    return await partners
      .updatePartner(id, payload)
      .then((response) => {
        commit('UPDATE_PARTNER', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async updateSettings({ commit }, { id, payload }) {
    return await partners
      .updateSettings(id, payload)
      .then((response) => {
        commit('UPDATE_PARTNER_SETTINGS', response.data.data)
        return response.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        return error.response.data
      })
  },

  async updateStatus({ commit }, { id }) {
    return await partners
      .updateStatus(id)
      .then((response) => {
        commit('UPDATE_PARTNER_STATUS', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async deletePartner({ commit }, id) {
    return await partners
      .deletePartner(id)
      .then(() => {
        commit('REMOVE_PARTNER', id)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
