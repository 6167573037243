<template>
  <CTableHead color="light">
    <CTableRow>
      <CTableHeaderCell
        scope="col"
        v-for="(column, index) in columns"
        :key="index"
        :class="[
          { ['column-' + index]: true },
          { ['with-sorter']: withSorter },
        ]"
        @click="(e) => onClickHeader(e, index)"
      >
        <div class="header-cell">
          <div class="text">{{ column }}</div>
          <div class="icon" v-if="withSorter && !exclude.includes(index)">
            <CIcon :icon="toggleIcon" size="sm" :key="toggler[index]" />
          </div>
        </div>
      </CTableHeaderCell>
    </CTableRow>
  </CTableHead>
</template>

<script>
import { ref, computed, onMounted } from 'vue'

export default {
  name: 'HeaderRow',

  props: {
    withSorter: {
      type: Boolean,
      default: false,
    },
    columns: {
      required: true,
    },
  },

  data() {
    return {
      exclude: ['action', 'status', 'extra', 'moreInfo'],
    }
  },

  setup(props, { emit }) {
    const toggler = ref([])
    const order = ref('ASC')

    const onClickHeader = (e, index) => {
      if (!props.withSorter) {
        e.stopPropagation()
        return
      }

      toggler.value[index] = !toggler.value[index]
      order.value = toggler.value[index] ? 'DESC' : 'ASC'

      emit('sort:column', { index, order: order.value })
    }

    const toggleIcon = computed(() => {
      return order.value == 'DESC' ? 'cil-chevron-bottom' : 'cil-chevron-top'
    })

    onMounted(() => {
      Object.entries(props.columns).forEach((col) => {
        toggler.value[col[0]] = false
      })
    })

    return { toggler, onClickHeader, toggleIcon }
  },

  emits: ['sort:column'],
}
</script>
