<template>
  <TableLayout :isLoading="isLoading" :columns="columns" :isEmpty="isEmpty">
    <template #list>
      <CTableRow v-for="country in countries" :key="country.numeric_code">
        <CTableDataCell scope="row" width="7%">
          <div class="numeric-code">
            <span>{{ country.numeric_code }}</span>
          </div>
        </CTableDataCell>
        <CTableDataCell width="10%">
          <div class="country-name">
            <flag :iso="country.alpha_2_code" />
            <span>{{ country.name }}</span>
          </div>
        </CTableDataCell>
        <CTableDataCell width="25%">
          {{ country.official_name }}
        </CTableDataCell>
      </CTableRow>
    </template>
  </TableLayout>
</template>

<script>
import store from '@/store'
import TableLayout from '@/layouts/TableLayout.vue'

export default {
  name: 'TableCountryCodes',

  components: {
    TableLayout,
  },

  data() {
    return {
      columns: {
        numeric_code: 'Code',
        name: 'Name',
        official_name: 'Official Name',
      },
    }
  },

  computed: {
    countries() {
      return store.state.countries?.countries
    },
    isLoading() {
      return store.state.countries?.loading || false
    },
    isEmpty() {
      return store.state.countries?.countries?.length == 0
    },
  },

  mounted() {
    if (store.state.countries?.countries?.length <= 0) {
      store.dispatch('countries/fetchCountries')
    }
  },
}
</script>

<style lang="scss" scoped>
.country-name {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  span {
    font-weight: 500;
  }
}

.numeric-code span {
  font-weight: 800;
  color: #034c8f;
}
</style>
