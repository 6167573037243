import smsCenter from '@/api/smsCenter'
import helpers from '@/utils/helpers'

const state = {
  loading: false,
  error: null,
  campaigns: [],
  history_logs: [],
}

const mutations = {
  SET_SMS_CAMPAIGNS(state, campaigns) {
    state.campaigns = campaigns
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  INSERT_SMS_CAMPAIGN(state, campaign) {
    if (state.campaigns?.data) {
      state.campaigns.data = [].concat(campaign, state.campaigns.data)
    }
  },
  UPDATE_SMS_CAMPAIGN(state, campaign) {
    if (state.campaigns?.data) {
      const index = state.campaigns.data.findIndex(
        (item) => item.id === campaign.id,
      )
      state.campaigns.data[index] = campaign
    }
  },
  REMOVE_SMS_CAMPAIGN(state, id) {
    if (state.campaigns?.data) {
      const index = state.campaigns.data.findIndex((item) => item.id === id)
      if (~index) state.campaigns.data.splice(index, 1)
    }
  },
  SET_SMS_HISTORY_LOGS(state, history_logs) {
    state.history_logs = history_logs
  },
}

const actions = {
  async fetchSmsCampaigns({ commit }, data) {
    commit('SET_LOADING', true)
    return await smsCenter
      .fetchCampaigns(data)
      .then((response) => {
        commit('SET_SMS_CAMPAIGNS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async createCampaign({ commit }, data) {
    commit('SET_LOADING', true)
    return await smsCenter
      .createCampaign(data)
      .then((response) => {
        commit('INSERT_SMS_CAMPAIGN', response.data?.data)
        commit('SET_LOADING', false)
        return response.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
        return error
      })
  },

  async updateCampaign({ commit }, { id, payload }) {
    commit('SET_LOADING', true)
    return await smsCenter
      .updateCampaign(id, payload)
      .then((response) => {
        commit('UPDATE_SMS_CAMPAIGN', response.data?.data)
        commit('SET_LOADING', false)
        return response.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
        return error
      })
  },

  async deleteCampaign({ commit }, id) {
    return await smsCenter
      .deleteCampaign(id)
      .then(() => {
        commit('REMOVE_SMS_CAMPAIGN', id)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async fetchHistoryLogs({ commit }, data) {
    commit('SET_LOADING', true)
    return await smsCenter
      .fetchHistoryLogs(data)
      .then((response) => {
        commit('SET_SMS_HISTORY_LOGS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
