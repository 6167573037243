import fields from '@/api/fields'
import helpers from '@/utils/helpers'

const state = {
  fields: [],
  loading: false,
  error: null,
}

const mutations = {
  SET_FIELDS(state, fields) {
    state.fields = fields
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  INSERT_FIELD(state, field) {
    state.fields = [].concat(state.fields, field)
  },
  UPDATE_FIELD(state, field) {
    const index = state.fields.findIndex((item) => item.id === field.id)
    state.fields[index] = field
  },
  REMOVE_FIELD(state, id) {
    const index = state.fields.findIndex((item) => item.id === id)
    if (~index) state.fields.splice(index, 1)
  },
}

const actions = {
  async fetchFields({ commit }) {
    commit('SET_LOADING', true)
    return await fields
      .fetchFields()
      .then((response) => {
        commit('SET_FIELDS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async addField({ commit }, payload) {
    return await fields
      .addField(payload)
      .then((response) => {
        commit('INSERT_FIELD', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async updateField({ commit }, { id, payload }) {
    return await fields
      .updateField(id, payload)
      .then((response) => {
        commit('UPDATE_FIELD', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async deleteField({ commit }, id) {
    return await fields
      .deleteField(id)
      .then(() => {
        commit('REMOVE_FIELD', id)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
