import skiptraze from '@/api/skiptraze'
import routerSkiptraze from '@/skiptraze/router'
import helpers from '@/utils/helpers'

const state = {
  user: null,
  counter: [],
  history: [],
  requests: [],
  loggedIn: false,
  loading: false,
  error: null,
}

const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_COUNTER(state, counter) {
    state.counter = counter
  },
  SET_HISTORY(state, history) {
    state.history = history
  },
  SET_REQUESTS(state, requests) {
    state.requests = requests
  },
  SET_LOGGED_IN(state, value) {
    state.loggedIn = value
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
}

const actions = {
  async login({ commit }, form) {
    commit('SET_LOADING', true)

    return await skiptraze
      .login(form)
      .then((response) => {
        commit('SET_LOGGED_IN', true)
        commit('SET_USER', response.data.data)

        setTimeout(() => {
          commit('SET_LOADING', false)
          routerSkiptraze.push({ path: '/dashboard' })
        }, 1000)

        return response
      })
      .catch((error) => {
        routerSkiptraze.push({ path: '/login' })
        commit('SET_LOADING', false)
        commit('SET_USER', null)
        commit('SET_ERROR', helpers.getError(error, 'skiptraze'))
        return error.response
      })
  },

  async logout({ commit }) {
    commit('SET_LOADING', true)

    return await skiptraze
      .logout()
      .then(() => {
        if (routerSkiptraze.currentRoute.name !== 'login') {
          commit('SET_LOGGED_IN', false)
          commit('SET_USER', null)

          setTimeout(() => {
            commit('SET_LOADING', false)
            routerSkiptraze.push({ path: '/login' })
          }, 500)
        }
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', helpers.getError(error, 'skiptraze'))
      })
  },

  async fetchDashboardCounter({ commit }) {
    commit('SET_LOADING', true)

    return await skiptraze
      .fetchDashboardCounter()
      .then((response) => {
        commit('SET_COUNTER', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', helpers.getError(error, 'skiptraze'))
      })
  },

  async fetchDashboardHistory({ commit }) {
    commit('SET_LOADING', true)

    return await skiptraze
      .fetchDashboardHistory()
      .then((response) => {
        commit('SET_HISTORY', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', helpers.getError(error, 'skiptraze'))
      })
  },

  async fetchRequests({ commit }, payload) {
    commit('SET_LOADING', true)

    return await skiptraze
      .fetchRequests(payload)
      .then((response) => {
        commit('SET_LEADS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', helpers.getError(error, 'skiptraze'))
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
