import moment from 'moment'
import CryptoJS from 'crypto-js'
import store from '@/store'
import storePublisher from '@/publisher/store'
import storeDocumentation from '@/casheddy/store'
import storeSkiptraze from '@/skiptraze/store'
import Bugsnag from '@bugsnag/js'

export function eventHandler(event) {
  event.preventDefault()
  event.stopPropagation()
}

export function insertToast(title, message, status) {
  const toast = {
    title,
    status,
    content: message,
    duration: moment().fromNow(),
  }

  store.dispatch('toast/insertToast', toast)
}

export function getDefaultDates() {
  return {
    start: moment().startOf('month').format('yyyy-MM-DD'),
    end: moment().format('yyyy-MM-DD'),
  }
}

export function capitalizeFirst(word) {
  const first = word.charAt(0).toUpperCase()
  return first + word.slice(1)
}

export function toSnakeCase(word) {
  return word.split(' ').join('_').toLowerCase()
}

export function neutralizeData(data) {
  if (typeof data === 'string') {
    return data.toLowerCase()
  }

  return data
}

export function sanitizeUsername(username) {
  if (username.includes('casheddy')) {
    return 'casheddy'
  }

  return username
}

export function sortDataByColumn(data, column, order) {
  if (data?.length <= 0) {
    return data
  }

  return data.sort((a, b) => {
    let source = neutralizeData(a[column])
    let target = neutralizeData(b[column])

    if (source < target) {
      return order === 'ASC' ? -1 : 1
    }

    if (source > target) {
      return order === 'ASC' ? 1 : -1
    }

    return 0
  })
}

export default {
  async checkLogin(event, form) {
    const payload = {
      email: form.email,
      password: form.password,
    }

    const response = await store.dispatch('auth/login', payload)
    if (response.status === 200) {
      store.state.forms.form = {}
      return new Promise((resolve) => {
        resolve()
      })
    }

    insertToast('Login Error', response.data.message, 'error')
    eventHandler(event)

    return new Promise((reject) => {
      reject()
    })
  },

  async checkPublisherLogin(event, form) {
    const payload = {
      username: form.username,
      password: form.password,
    }

    const response = await storePublisher.dispatch('publishers/login', payload)
    if (response.status === 200) {
      storePublisher.state.forms.form = {}
      return new Promise((resolve) => {
        resolve()
      })
    }

    insertToast('Login Error', response.data.message, 'error')
    eventHandler(event)

    return new Promise((reject) => {
      reject()
    })
  },

  async checkSkiptrazeLogin(event, form) {
    const payload = {
      email: form.email,
      password: form.password,
    }

    const response = await storeSkiptraze.dispatch('skiptraze/login', payload)
    if (response.status === 200) {
      storeSkiptraze.state.forms.form = {}
      return new Promise((resolve) => {
        resolve()
      })
    }

    insertToast('Login Error', response.data.message, 'error')
    eventHandler(event)

    return new Promise((reject) => {
      reject()
    })
  },

  async decryptData(data) {
    const latinEnc = CryptoJS.enc.Latin1

    const base64Data = data.replace(/-/g, '+').replace(/_/g, '/')
    const decoded = CryptoJS.enc.Base64.parse(base64Data).toString(latinEnc)
    const encryptionKey = process.env.VUE_APP_FIRST_ENCRYPTION_KEY || ''

    if (!encryptionKey || encryptionKey === '') {
      return ''
    }

    const key = CryptoJS.enc.Base64.parse(encryptionKey).toString(latinEnc)

    const length = 16
    const iv = latinEnc.parse(decoded.substring(0, length))
    const firstEncrypted = latinEnc.parse(decoded.substring(length + 64))

    const configCipher = { ciphertext: firstEncrypted }
    const ciphertext = CryptoJS.lib.CipherParams.create(configCipher)

    const config = {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }

    const parsedKey = latinEnc.parse(key)
    const decrypted = CryptoJS.AES.decrypt(ciphertext, parsedKey, config)
    const decryptedText = decrypted.toString(latinEnc)

    const utf8Enc = CryptoJS.enc.Utf8
    const value = CryptoJS.enc.Base64.parse(decryptedText).toString(utf8Enc)

    return new Promise((resolve) => {
      resolve(value)
    })
  },

  async validate(event, page = '') {
    const form = event.currentTarget
    const isInvalid = form.checkValidity() === false
    if (isInvalid) {
      eventHandler(event)
    }

    if (page == 'skiptraze') {
      storeSkiptraze.dispatch('forms/updateFlag', true)
      return !isInvalid
    }

    if (page == 'publisher') {
      storePublisher.dispatch('forms/updateFlag', true)
      return !isInvalid
    }

    if (page == 'documentation') {
      storeDocumentation.dispatch('forms/updateFlag', true)
      return !isInvalid
    }

    store.dispatch('forms/updateFlag', true)
    return !isInvalid
  },

  getError(error, page = '') {
    if (error?.response && error?.response?.status == 401) {
      if (page == 'skiptraze') {
        Bugsnag.notify(new Error(error.response.data.message))
        storeSkiptraze.dispatch('publishers/logout')
        return error.response.data.message
      }

      if (page == 'publisher') {
        Bugsnag.notify(new Error(error.response.data.message))
        storePublisher.dispatch('publishers/logout')
        return error.response.data.message
      }

      Bugsnag.notify(new Error(error.response.data.message))
      store.dispatch('auth/logout')
      return error.response.data.message
    }

    if (error.name === 'ERR_AUTH_USER') {
      Bugsnag.notify(new Error(error.message))
      return error.message
    }

    if (error.message) {
      Bugsnag.notify(new Error(error.code + ' ' + error.message))
      return error.code + ' ' + error.message
    }

    Bugsnag.notify(new Error('API Error, Please try again.'))
    return 'API Error, Please try again.'
  },
}
