import axiosAPI from '@/api/axios'

export default {
  async fetchCampaigns(data) {
    return await axiosAPI.get('/api/sms-center/campaigns', { params: data })
  },

  async createCampaign(data) {
    return await axiosAPI.post('/api/sms-center/campaigns', data)
  },

  async updateCampaign(id, payload) {
    return await axiosAPI.post(`/api/sms-center/campaigns/${id}`, payload)
  },

  async deleteCampaign(id) {
    return await axiosAPI.delete(`/api/sms-center/campaigns/${id}`)
  },

  async fetchHistoryLogs(data) {
    return await axiosAPI.get('/api/sms-center/histories', { params: data })
  },
}
