<template>
  <div
    class="slide-menu"
    :class="visibility ? 'show-menu' : 'hide-menu'"
    id="slide-menu"
  >
    <ul>
      <li
        v-for="(item, index) in items"
        :key="index"
        class="scroll-to-link"
        :class="item.isActive ? 'active' : ''"
      >
        <SidebarMenuItemLink
          :name="item.name"
          :to="item.to"
          :onShowMenu="onShowMenu"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import SidebarMenuItemLink from '@/skiptraze/views/integration/sidebar/Menu/ItemLink.vue'

export default {
  name: 'SidebarMenuSlider',

  components: {
    SidebarMenuItemLink,
  },

  props: {
    items: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Boolean,
      default: true,
    },
    onShowMenu: Function,
  },
}
</script>
