<template>
  <CFooter>
    <div>
      <small class="text-muted"> {{ name }} | {{ address }} </small>
    </div>
    <small>
      <a :href="link" target="_blank">{{ name }}</a>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }}</span>
    </small>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',

  data() {
    return {
      name: '',
      address: '',
      link: '',
    }
  },

  mounted() {
    this.name = process.env.VUE_APP_NAME
    this.address = process.env.VUE_APP_ADDRESS
    this.link = process.env.VUE_APP_EXTERNAL_URL
  },
}
</script>
