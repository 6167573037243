<template>
  <div class="app-country">
    <FormSimpleSelector
      id="countrySelector"
      :options="countries"
      :modelValue="country"
      className="mb-0"
      @update:modelValue="(value) => onChangeConnection(value)"
    />
    <div class="d-flex justify-content-center me-0 me-md-2 mt-1">
      <CTooltip :content="title" placement="bottom">
        <template #toggler="{ on }">
          <CButton
            v-on="on"
            component="a"
            color="link"
            class="text-decoration-none"
            size="sm"
            @click="onToggleInfo"
          >
            <CIcon icon="cil-info" width="16" size="lg" />
          </CButton>
        </template>
      </CTooltip>
    </div>
    <COffcanvas
      :style="{ '--cui-offcanvas-width': '500px' }"
      placement="end"
      :visible="visible"
      @hide="onToggleInfo"
    >
      <COffcanvasHeader>
        <COffcanvasTitle>{{ title }}</COffcanvasTitle>
        <CCloseButton class="text-reset" @click="onHideInfo" />
      </COffcanvasHeader>
      <COffcanvasBody>
        <div class="country-codes-details">
          <div class="info-country-usage">
            <span class="note"> NOTE: </span>
            Use <span class="fw-bolder">"Code"</span> column to substitute for
            <i>{code}</i> in each country-specific URLs.
            <i>This parameter is optional and can be removed if not needed. </i>
            <i>
              By default, external or API conversions will be stored in 'spain'
              database.
            </i>
            <br />
            <div class="info-country-example">
              <span>
                Example for <b><i>(Romania): </i></b>
              </span>
              <div class="url-example">
                <span> {{ baseApiUrl }}</span>
                <span>/handler/click?code=</span>
                <span class="country-code">642</span>
                <span>&offer_id=1&affiliate_id=1</span>
              </div>
            </div>
          </div>
          <TableCountryCodes />
        </div>
      </COffcanvasBody>
    </COffcanvas>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import FormSimpleSelector from '@/components/forms/common/FormSimpleSelector.vue'
import TableCountryCodes from '@/components/tables/TableCountryCodes.vue'

export default {
  name: 'AppCountry',

  components: {
    FormSimpleSelector,
    TableCountryCodes,
  },

  data() {
    return {
      baseApiUrl: process.env.VUE_APP_API_BASE_URL,
    }
  },

  setup() {
    const title = ref('Country Codes')
    const visible = ref(false)

    const defaultCountry = ref('spain')
    const defaultCountries = ref([
      {
        label: 'Spain',
        value: 'spain',
      },
    ])

    const country = computed(() => {
      const connection = localStorage.getItem('connection')
      return connection || defaultCountry.value
    })

    const countries = computed(() => {
      const active = store.state.countries?.active
      if (!active || active.length === 0) {
        return defaultCountries.value
      }

      return store.state.countries?.active?.map((item) => {
        return {
          label: item.name,
          value: item.tag,
        }
      })
    })

    const onToggleInfo = () => {
      visible.value = !visible.value
    }

    const onHideInfo = () => {
      visible.value = false
    }

    const onChangeConnection = (value) => {
      setTimeout(() => {
        store.dispatch('countries/changeConnection', { country: value })
        store.dispatch('forms/updateForm', {})
      }, 500)
    }

    onMounted(() => {
      store.dispatch('countries/fetchActiveCountries')
      if (!localStorage.getItem('connection')) {
        localStorage.setItem('connection', defaultCountry.value)
      }
    })

    return {
      title,
      visible,
      country,
      countries,
      onToggleInfo,
      onHideInfo,
      onChangeConnection,
    }
  },
}
</script>

<style lang="scss" scoped>
.app-country {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.country-codes-details {
  font-weight: 500;

  span {
    font-weight: 500;
  }
}

.info-country-usage {
  font-size: 14px;
  line-height: 24px;

  span.note {
    font-size: 13px;
    font-weight: 600;
    color: #ff0000;
  }
}

.info-country-example {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 24px;

  .url-example {
    text-decoration: underline;
    font-size: 13px;
    color: #002f5a;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span.country-code {
    font-size: 13px;
    font-weight: 800;
    color: #034c8f !important;
  }
}
</style>
