export const main = [
  {
    name: 'Dashboard',
    icon: 'cil-speedometer',
    isActive: true,
    to: '/dashboard',
  },
  {
    name: 'Leads',
    icon: 'cil-gem',
    isActive: false,
    to: '/leads',
  },
  {
    name: 'Statistics',
    icon: 'cil-chart',
    isActive: false,
    to: '/statistics',
  },
  {
    name: 'API Integration',
    icon: 'cil-notes',
    isActive: false,
    to: '/integration',
  },
]

export const settings = [
  {
    name: 'Postback URL',
    icon: 'cil-link',
    isActive: false,
    to: '/configuration',
  },
  {
    name: 'Logout',
    icon: 'cil-account-logout',
    isActive: false,
    to: '/logout',
  },
]
