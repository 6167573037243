import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'

import auth from '@/store/modules/auth'
import domains from '@/store/modules/domains'
import toast from '@/store/modules/toast'
import forms from '@/store/modules/forms'
import sidebar from '@/store/modules/sidebar'
import offers from '@/store/modules/offers'
import affiliates from '@/store/modules/affiliates'
import postback from '@/store/modules/postback'
import conversion from '@/store/modules/conversion'
import logs from '@/store/modules/logs'
import statistics from '@/store/modules/statistics'
import partners from '@/store/modules/partners'
import accounts from '@/store/modules/accounts'
import options from '@/store/modules/options'
import documentation from '@/store/modules/documentation'
import smsCenter from '@/store/modules/smsCenter'
import countries from '@/store/modules/countries'
import pingtree from '@/store/modules/pingtree'
import fields from '@/store/modules/fields'

export default createStore({
  modules: {
    auth,
    domains,
    toast,
    forms,
    sidebar,
    offers,
    affiliates,
    postback,
    conversion,
    logs,
    statistics,
    partners,
    accounts,
    options,
    documentation,
    smsCenter,
    countries,
    pingtree,
    fields,
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'documentation.partner'],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 3, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
})
