<template>
  <CNavItem
    v-for="(item, index) in list"
    :key="index"
    :class="item.isActive ? 'active' : ''"
  >
    <CNavLink href="javascript:void(0);" @click="onClickMenuItem(item.to)">
      <CIcon customClassName="nav-icon" :icon="item.icon" />
      {{ item.name }}
    </CNavLink>
  </CNavItem>
</template>

<script>
import routerSkiptraze from '@/skiptraze/router'

export default {
  name: 'SkiptrazeSidebarItem',

  props: {
    list: {
      required: true,
    },
  },

  methods: {
    onClickMenuItem(dest) {
      routerSkiptraze.push({ path: dest })
    },
  },
}
</script>
