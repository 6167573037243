import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'

import skiptraze from '@/skiptraze/store/modules/skiptraze'
import toast from '@/skiptraze/store/modules/toast'
import forms from '@/skiptraze/store/modules/forms'
import sidebar from '@/skiptraze/store/modules/sidebar'

export default createStore({
  modules: {
    skiptraze,
    toast,
    forms,
    sidebar,
  },
  plugins: [
    createPersistedState({
      paths: ['skiptraze'],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 3, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
})
