<template>
  <li :class="item.isActive ? 'active' : ''">
    <slot name="menu-item"></slot>
  </li>
  <SidebarMenuSlider
    v-if="item.isSlideMenu"
    :items="item.children"
    :visibility="visibility"
    :onShowMenu="onShowMenu"
  />
</template>

<script>
import SidebarMenuSlider from '@/skiptraze/views/integration/sidebar/Menu/Slider.vue'

export default {
  name: 'SidebarMenuItem',

  components: {
    SidebarMenuSlider,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Boolean,
      default: true,
    },
    onShowMenu: Function,
  },
}
</script>
