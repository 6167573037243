import axiosAPI from '@/api/axios'

export default {
  async fetchFields() {
    return await axiosAPI.get('/api/fields')
  },

  async addField(payload) {
    return await axiosAPI.post('/api/fields', payload)
  },

  async updateField(id, payload) {
    return await axiosAPI.post(`/api/fields/${id}`, payload)
  },

  async deleteField(id) {
    return await axiosAPI.delete(`/api/fields/${id}`)
  },
}
