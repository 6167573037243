import axios from 'axios'
import { insertToast } from '@/utils/helpers'
import store from '@/store'
import router from '@/router'
import routerPublisher from '@/publisher/router'

const axiosAPI = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true,
})

axiosAPI.interceptors.request.use(
  function (config) {
    /* XSRF for safari */
    const token = localStorage.getItem('access_token')
    config.headers['Authorization'] = `Bearer ${token}`
    config.headers['X-XSRF-TOKEN'] = store.state.auth.csrf

    // attach cancel token and store state
    const source = axios.CancelToken.source()
    config.cancelToken = source.token

    const requestKey = router.currentRoute.value.name
    store.commit('auth/ADD_CANCEL_TOKEN', { key: requestKey, source })
    return config
  },
  function (err) {
    return Promise.reject(err)
  },
)

/*
 * Add a response interceptor
 */
axiosAPI.interceptors.response.use(
  (response) => response,
  function (error) {
    if ([400].includes(error.response.status)) {
      insertToast('Warning', error.response.data.message, 'warning')
      return Promise.reject(error)
    }

    if ([422, 500].includes(error.response.status)) {
      insertToast('Error', error.response.data.message, 'error')
      return Promise.reject(error)
    }

    if ([401, 403, 409].includes(error.response.status)) {
      if (error.response.config.url.includes('publisher')) {
        routerPublisher.push('/login')
        store.dispatch('publishers/logout')
        return Promise.reject(error)
      }

      router.push('/login')
      store.dispatch('auth/logout')
      return Promise.reject(error)
    }

    if ([419].includes(error.response.status)) {
      if (error.response.config.url.includes('publisher')) {
        routerPublisher.push('/login')
        window.location.reload()
        return Promise.reject(error)
      }

      router.push('/login')
      window.location.reload()
      return Promise.reject(error)
    }

    if ([500].includes(error.response.status)) {
      router.push('/500')
      return Promise.reject(error)
    }

    return Promise.reject(error)
  },
)

export default axiosAPI
