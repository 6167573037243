import options from '@/api/options'
import helpers from '@/utils/helpers'

const state = {
  options: {},
  loading: false,
  error: null,
}

const mutations = {
  SET_OPTIONS(state, options) {
    state.options = options
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
}

const actions = {
  async fetchOptions({ commit }) {
    commit('SET_LOADING', true)
    return await options
      .fetchOptions()
      .then((response) => {
        commit('SET_OPTIONS', response.data.data)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async updateOptions({ commit }, payload) {
    return await options
      .updateOptions(payload)
      .then((response) => {
        commit('SET_OPTIONS', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async createCustomReport({ commit }, payload) {
    return await options
      .createCustomReport(payload)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        return error.response?.data
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
