import countries from '@/api/countries'
// import { checkIfPathIsGlobal } from '@/utils/globals'
import helpers from '@/utils/helpers'

const state = {
  countries: [],
  active: [],
  loading: false,
  error: null,
  select_loading: false,
  select_error: null,
  renderKey: 0,
  minimum: 1,
  maximum: 99999,
}

const mutations = {
  UPDATE_RENDER_KEY(state) {
    const max = state.maximum
    const min = state.minimum
    state.renderKey = Math.floor(Math.random() * (max - min + 1) + min)
  },
  SET_COUNTRIES(state, countries) {
    state.countries = countries
  },
  SET_ACTIVE_COUNTRIES(state, countries) {
    state.active = countries
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  SET_SELECT_LOADING(state, loading) {
    state.select_loading = loading
  },
  SET_SELECT_ERROR(state, error) {
    state.select_error = error
  },
  INSERT_COUNTRY(state, country) {
    state.countries = [].concat(country, state.countries)
  },
  UPDATE_COUNTRY(state, country) {
    const index = state.countries.findIndex((item) => item.id === country.id)
    state.countries[index] = country
  },
  UPDATE_COUNTRY_STATUS(state, country) {
    const index = state.countries.findIndex((item) => item.id === country.id)
    state.countries[index].enabled = country.enabled
  },
  REMOVE_COUNTRY(state, id) {
    const index = state.countries.findIndex((item) => item.id === id)
    if (~index) state.countries.splice(index, 1)
  },
}

const actions = {
  // updateRenderKey({ commit, rootState }) {
  //   const isGlobal = checkIfPathIsGlobal(rootState.auth.route.path)
  //   if (!isGlobal) {
  //     commit('UPDATE_RENDER_KEY')
  //   }
  // },

  async fetchCountries({ commit }) {
    commit('SET_LOADING', true)
    return await countries
      .fetchCountries()
      .then((response) => {
        commit('SET_COUNTRIES', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchActiveCountries({ commit }) {
    return await countries
      .fetchActiveCountries()
      .then((response) => {
        commit('SET_ACTIVE_COUNTRIES', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_SELECT_ERROR', helpers.getError(error))
      })
  },

  async addCountry({ commit }, payload) {
    return await countries
      .addCountry(payload)
      .then((response) => {
        commit('INSERT_COUNTRY', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async changeConnection({ commit }, payload) {
    localStorage.setItem('connection', payload.country)
    // const isGlobal = checkIfPathIsGlobal(rootState.auth.route.path)

    commit('SET_SELECT_LOADING', true)
    return await countries
      .changeConnection(payload)
      .then(() => {
        commit('UPDATE_RENDER_KEY')
        commit('SET_SELECT_LOADING', false)
      })
      .catch((error) => {
        commit('SET_SELECT_ERROR', helpers.getError(error))
        commit('SET_SELECT_LOADING', false)
      })
  },

  async updateCountry({ commit }, { id, payload }) {
    return await countries
      .updateCountry(id, payload)
      .then((response) => {
        commit('UPDATE_COUNTRY', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async updateStatus({ commit }, { id, status }) {
    return await countries
      .updateStatus({ id, status })
      .then((response) => {
        commit('UPDATE_COUNTRY_STATUS', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async deleteCountry({ commit }, id) {
    return await countries
      .deleteCountry(id)
      .then(() => {
        commit('REMOVE_COUNTRY', id)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
