<template>
  <CToaster :placement="placement" visible>
    <CToast v-for="(toast, index) in toasts" :key="index" visible>
      <CToastHeader closeButton>
        <SvgToastIcon :status="toast.status" />
        <span class="me-auto fw-bold">{{ toast.title }}</span>
        <small>{{ toast.duration }}</small>
      </CToastHeader>
      <CToastBody>
        {{ toast.content }}
      </CToastBody>
    </CToast>
  </CToaster>
</template>

<script>
import store from '@/store'
import { CToaster } from '@coreui/vue'
import SvgToastIcon from '@/components/others/SvgToastIcon.vue'
import useBreakpoints from 'vue-next-breakpoints'

export default {
  name: 'AppToast',

  components: {
    SvgToastIcon,
    CToaster,
  },

  setup() {
    const breakpoints = useBreakpoints({
      mobile: 991,
      desktop: [992],
    })

    return {
      breakpoints,
    }
  },

  computed: {
    placement() {
      if (this.breakpoints.desktop.matches) {
        return 'top-end'
      }

      return 'top-start'
    },

    toasts() {
      return store.state.toast.toasts
    },
  },
}
</script>
