<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="d-lg-none ps-1" @click="onToggleSidebar">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav class="d-none d-lg-flex me-auto fw-bolder">
        <CHeaderText>{{ title }}</CHeaderText>
      </CHeaderNav>
      <CHeaderNav class="d-none d-lg-flex me-3" v-if="username !== ''">
        <CHeaderText class="d-flex">
          <span class="pe-2">{{ loggedHeader }}</span>
          <div class="fw-bolder">{{ username }}</div>
        </CHeaderText>
      </CHeaderNav>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CImage fluid class="my-3" :src="logo" :width="120" alt="LOGO" />
      </CHeaderBrand>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <SkiptrazeBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import storeSkiptraze from '@/skiptraze/store'
import logo from '@/assets/brand/fineddy/logo.png'
import SkiptrazeBreadcrumb from '@/skiptraze/views/Breadcrumb.vue'

export default {
  name: 'SkiptrazeHeader',

  components: {
    SkiptrazeBreadcrumb,
  },

  setup() {
    return {
      logo,
    }
  },

  props: {
    username: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loggedHeader: 'Logged in as',
      title: 'Skiptraze v1',
    }
  },

  methods: {
    onToggleSidebar() {
      storeSkiptraze.dispatch('sidebar/toggleSidebar')
    },
  },
}
</script>
