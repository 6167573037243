import axiosAPI from '@/api/axios'

export default {
  async fetchPartners() {
    return await axiosAPI.get('/api/partners')
  },

  async addPartner(payload) {
    return await axiosAPI.post('/api/partners', payload)
  },

  async updatePartner(id, payload) {
    return await axiosAPI.post(`/api/partners/${id}`, payload)
  },

  async updateSettings(id, payload) {
    return await axiosAPI.post(`/api/partners/${id}/settings`, payload)
  },

  async updateStatus(id) {
    return await axiosAPI.post(`/api/partners/${id}/status`)
  },

  async deletePartner(id) {
    return await axiosAPI.delete(`/api/partners/${id}`)
  },
}
