<template>
  <ul>
    <SidebarMenuItem
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      :visibility="visibility"
      :onShowMenu="onShowMenu"
    >
      <template #menu-item v-if="item.isSlideMenu">
        <div
          class="content-slide-menu"
          @click="onToggleMenu"
          id="link-slide-menu"
        >
          <SidebarMenuItemLink :name="item.name" :onShowMenu="onShowMenu" />
          <CButton
            component="a"
            color="link"
            class="text-decoration-none ms-auto"
            size="sm"
          >
            <CIcon icon="cil-caret-top" size="sm" v-if="visibility" />
            <CIcon icon="cil-caret-bottom" size="sm" v-else />
          </CButton>
        </div>
      </template>
      <template #menu-item v-else>
        <SidebarMenuItemLink
          :name="item.name"
          :to="item.to"
          :onShowMenu="onShowMenu"
        />
      </template>
    </SidebarMenuItem>
  </ul>
</template>

<script>
import SidebarMenuItem from '@/skiptraze/views/integration/sidebar/Menu/Item.vue'
import SidebarMenuItemLink from '@/skiptraze/views/integration/sidebar/Menu/ItemLink.vue'

export default {
  name: 'SidebarMenu',

  components: {
    SidebarMenuItem,
    SidebarMenuItemLink,
  },

  props: {
    items: {
      type: Object,
      required: true,
    },
    onShowMenu: Function,
  },

  data() {
    return {
      visibility: true,
    }
  },

  methods: {
    onToggleMenu(event) {
      event.preventDefault()
      this.visibility = !this.visibility
    },
  },
}
</script>
