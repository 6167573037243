const state = {
  sidebarVisible: true,
  sidebarUnfoldable: false,
}

const mutations = {
  TOGGLE_SIDEBAR(state) {
    state.sidebarVisible = !state.sidebarVisible
  },
  TOGGLE_UNFOLDABLE(state) {
    state.sidebarUnfoldable = !state.sidebarUnfoldable
  },
  UPDATE_SIDEBAR_VISIBLE(state, payload) {
    state.sidebarVisible = payload.value
  },
  UPDATE_SIDEBAR(state, value) {
    state.sidebarVisible = value
  },
  UPDATE_SIDEBAR_UNFOLDABLE(state, value) {
    state.sidebarUnfoldable = value
  },
}

const actions = {
  toggleSidebar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  toggleUnfoldable({ commit }) {
    commit('TOGGLE_UNFOLDABLE')
  },
  updateSidebar({ commit }, value) {
    commit('UPDATE_SIDEBAR', value)
  },
  updateSidebarVisible({ commit }, value) {
    commit({
      type: 'UPDATE_SIDEBAR_VISIBLE',
      value,
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
