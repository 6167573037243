<template>
  <AppToast />
  <AppSidebar :onClick="onHandleClick">
    <template #app-sidebar>
      <SkiptrazeSidebar />
    </template>
  </AppSidebar>
  <div class="wrapper d-flex flex-column min-vh-100 bg-light">
    <SkiptrazeHeader :username="username" />
    <div class="body flex-grow-1 px-3">
      <CContainer lg>
        <router-view />
      </CContainer>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import routerSkiptraze from '@/skiptraze/router'
import storeSkiptraze from '@/skiptraze/store'
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/app/AppFooter.vue'
import AppSidebar from '@/components/app/AppSidebar.vue'
import AppToast from '@/components/app/AppToast.vue'
import SkiptrazeHeader from '@/skiptraze/views/Header.vue'
import SkiptrazeSidebar from '@/skiptraze/views/Sidebar.vue'

export default {
  name: 'SkiptrazeLayout',

  components: {
    AppFooter,
    AppSidebar,
    AppToast,
    SkiptrazeHeader,
    SkiptrazeSidebar,
    CContainer,
  },

  computed: {
    username() {
      return storeSkiptraze.state.skiptraze.user?.username
    },
  },

  methods: {
    onHandleClick() {
      routerSkiptraze.push('/dashboard')
    },
  },
}
</script>
