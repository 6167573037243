import axiosAPI from '@/api/axios'

export default {
  async fetchPostbackList() {
    return await axiosAPI.get('/api/postback')
  },

  async fetchExistingUrls(params) {
    return await axiosAPI.get('/api/postback/existing', {
      params: {
        offer_ids: params?.offer_ids,
        affiliate_ids: params?.affiliate_ids,
      },
    })
  },

  async generatePostback(payload) {
    return await axiosAPI.post('/api/postback/url', payload)
  },

  async updatePostback(payload) {
    return await axiosAPI.post('/api/postback/url/update', payload)
  },

  async updatePostbackUrl(id, payload) {
    return await axiosAPI.post(`/api/postback/url/${id}/update`, payload)
  },

  async handler(payload) {
    return await axiosAPI.get('/handler', { params: payload })
  },
}
