import accounts from '@/api/accounts'
import helpers from '@/utils/helpers'

const state = {
  accounts: [],
  loading: false,
  error: null,
}

const mutations = {
  SET_ACCOUNTS(state, accounts) {
    state.accounts = accounts
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  INSERT_ACCOUNT(state, account) {
    state.accounts = [].concat(account, state.accounts)
  },
  UPDATE_ACCOUNT(state, account) {
    const index = state.accounts.findIndex((item) => item.id === account.id)
    state.accounts[index] = account.email
  },
  REMOVE_ACCOUNT(state, id) {
    const index = state.accounts.findIndex((item) => item.id === id)
    if (~index) state.accounts.splice(index, 1)
  },
}

const actions = {
  async fetchAccounts({ commit }) {
    commit('SET_LOADING', true)
    return await accounts
      .fetchAccounts()
      .then((response) => {
        commit('SET_ACCOUNTS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async addAccount({ commit }, payload) {
    return await accounts
      .addAccount(payload)
      .then((response) => {
        commit('INSERT_ACCOUNT', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async updateAccount({ commit }, { id, payload }) {
    return await accounts
      .updateAccount(id, payload)
      .then((response) => {
        commit('UPDATE_ACCOUNT', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async resetPassword({ commit }, { id, payload }) {
    return await accounts
      .resetPassword(id, payload)
      .then((response) => {
        commit('UPDATE_ACCOUNT', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async deleteAccount({ commit }, id) {
    return await accounts
      .deleteAccount(id)
      .then(() => {
        commit('REMOVE_ACCOUNT', id)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
