import documentation from '@/api/documentation'

const state = {
  partner: null,
  isLoading: false,
}

const mutations = {
  SET_PARTNER(state, partner) {
    state.partner = partner
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
}

const actions = {
  async verifyPartner({ commit }, payload) {
    commit('SET_LOADING', true)
    return await documentation
      .verifyPartner(payload)
      .then((response) => {
        commit('SET_PARTNER', response.data.data)
        commit('SET_LOADING', false)
        return response.data
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        return error.response.data
      })
  },

  setPartner({ commit }, payload) {
    commit('SET_PARTNER', payload)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
