<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="onUpdateSidebarVisibility"
  >
    <CSidebarBrand @click.prevent="onHandleClick">
      <CImage
        fluid
        class="sidebar-brand-full logo my-1"
        :src="brand"
        :width="120"
        alt="LOGO"
      />
      <CImage
        fluid
        class="sidebar-brand-narrow icon my-1"
        :src="brandIcon"
        alt="ICON"
      />
    </CSidebarBrand>
    <slot name="app-sidebar"></slot>
    <CSidebarToggler class="d-none d-lg-flex" @click="onToggleFold" />
  </CSidebar>
</template>

<script>
import store from '@/store'
import brand from '@/assets/brand/fineddy/logo.png'
import brandIcon from '@/assets/brand/fineddy/icon.png'
import useBreakpoints from 'vue-next-breakpoints'

export default {
  name: 'AppSidebar',

  props: {
    onClick: {
      type: Function,
      required: true,
    },
  },

  setup() {
    const breakpoints = useBreakpoints({
      mobile: 991,
      desktop: [992],
    })

    return {
      brand,
      brandIcon,
      breakpoints,
    }
  },

  computed: {
    sidebarVisible() {
      return store.state.sidebar.sidebarVisible
    },
    sidebarUnfoldable() {
      return store.state.sidebar.sidebarUnfoldable
    },
  },

  methods: {
    onUpdateSidebarVisibility(event) {
      store.dispatch('sidebar/updateSidebarVisible', event)
    },

    onToggleFold() {
      store.dispatch('sidebar/toggleUnfoldable')
    },

    onHandleClick() {
      this.onClick()
    },

    onScreenResize() {
      if (this.breakpoints.desktop.matches) {
        store.dispatch('sidebar/updateSidebarVisible', true)
      }
    },
  },

  mounted() {
    window.addEventListener('resize', this.onScreenResize)
  },

  unmounted() {
    window.removeEventListener('resize', this.onScreenResize)
  },
}
</script>
