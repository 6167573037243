import { createRouter, createWebHashHistory } from 'vue-router'
import storePublisher from '@/publisher/store'

import PublisherLayout from '@/publisher/views/Layout'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/login',
    meta: { guestOnly: true },
  },
  {
    path: '/login',
    name: 'Login',
    meta: { guestOnly: true },
    component: () =>
      import(/* webpackChunkName: "login" */ '@/publisher/views/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () =>
      import(/* webpackChunkName: "logout" */ '@/publisher/views/Logout.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: PublisherLayout,
    meta: { authOnly: true },
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: '',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/publisher/views/Dashboard.vue'
          ),
      },
    ],
  },
  {
    path: '/leads',
    name: 'Leads',
    component: PublisherLayout,
    meta: { authOnly: true },
    redirect: '/leads',
    children: [
      {
        path: '/leads',
        name: '',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/publisher/views/Leads.vue'
          ),
      },
    ],
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: PublisherLayout,
    meta: { authOnly: true },
    redirect: '/statistics',
    children: [
      {
        path: '/statistics',
        name: '',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/publisher/views/Statistics.vue'
          ),
      },
    ],
  },
  {
    path: '/integration',
    name: 'API Integration',
    component: PublisherLayout,
    meta: { authOnly: true },
    children: [
      {
        path: '/integration',
        name: '',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/publisher/views/Integration.vue'
          ),
      },
    ],
  },
  {
    path: '/configuration',
    name: 'Postback URL',
    component: PublisherLayout,
    meta: { authOnly: true },
    children: [
      {
        path: '/configuration',
        name: '',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/publisher/views/Configuration.vue'
          ),
      },
    ],
  },
  {
    path: '/500',
    name: 'Server Error',
    component: () =>
      import(/* webpackChunkName: "500" */ '@/views/errors/500.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page Not Found',
    component: () =>
      import(/* webpackChunkName: "404" */ '@/views/errors/404.vue'),
  },
]

const routerPublisher = createRouter({
  history: createWebHashHistory('/affiliates/'),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

routerPublisher.beforeEach((to, from, next) => {
  // reset all forms
  storePublisher.dispatch('forms/updateForm', {})

  if (to.matched.some((record) => record.meta.authOnly)) {
    // if user is not logged in
    if (
      !storePublisher.state.publishers?.loggedIn ||
      !storePublisher.state.publishers?.user?.is_publisher
    ) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
      return
    }
  }

  // guest route
  if (to.matched.some((record) => record.meta.guestOnly)) {
    // if user is authenticated
    if (
      storePublisher.state.publishers?.loggedIn &&
      storePublisher.state.publishers?.user?.is_publisher
    ) {
      next({
        path: '/dashboard',
        query: { redirect: to.fullPath },
      })
      return
    }
  }

  next()
  return
})

export default routerPublisher
