import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibGoogle,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibCcStripe,
  cibCcPaypal,
  cibCcApplePay,
  cibCcAmex,
} from '@coreui/icons'
import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from '@coreui/icons'
import {
  cilAccountLogout,
  cilAddressBook,
  cilAlbum,
  cilArrowBottom,
  cilArrowRight,
  cilArrowThickBottom,
  cilArrowThickFromLeft,
  cilArrowThickTop,
  cilArrowTop,
  cilAsterisk,
  cilAt,
  cilAudioDescription,
  cilAvTimer,
  cilBadge,
  cilBan,
  cilBank,
  cilBarChart,
  cilBarcode,
  cilBasket,
  cilBell,
  cilBolt,
  cilBook,
  cilBrowser,
  cilBullhorn,
  cilCalculator,
  cilCalendar,
  cilCaretBottom,
  cilCaretTop,
  cilCash,
  cilCasino,
  cilClone,
  cilCloudDownload,
  cilChart,
  cilChartPie,
  cilCheck,
  cilCheckAlt,
  cilChevronBottom,
  cilChevronDoubleRight,
  cilChevronTop,
  cilCheckCircle,
  cilClock,
  cilCloudy,
  cilCode,
  cilCog,
  cilCommentSquare,
  cilColumns,
  cilContact,
  cilCursor,
  cilDescription,
  cilDevices,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilExitToApp,
  cilExternalLink,
  cilGem,
  cilGlobeAlt,
  cilGrid,
  cilFaceDead,
  cilFile,
  cilFilter,
  cilFlagAlt,
  cilFont,
  cilHandPointUp,
  cilHandPointRight,
  cilHttps,
  cilInbox,
  cilInfo,
  cilInput,
  cilJustifyCenter,
  cilLan,
  cilLaptop,
  cilLayers,
  cilLifeRing,
  cilLightbulb,
  cilLink,
  cilLinkAlt,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilLockUnlocked,
  cilLoop,
  cilLoopCircular,
  cilLowVision,
  cilMagnifyingGlass,
  cilMediaPlay,
  cilMenu,
  cilMinus,
  cilMobile,
  cilMobileLandscape,
  cilMoney,
  cilMoon,
  cilNewspaper,
  cilNotes,
  cilObjectGroup,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPlus,
  cilPuzzle,
  cilScreenSmartphone,
  cilSettings,
  cilShare,
  cilShieldAlt,
  cilShortText,
  cilSitemap,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilStream,
  cilSync,
  cilTag,
  cilTags,
  cilTask,
  cilThumbUp,
  cilThumbDown,
  cilTransfer,
  cilTrash,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserPlus,
  cilUserUnfollow,
  cilUserX,
  cilWallet,
  cilWindow,
  cilX,
  cilXCircle,
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilAccountLogout,
    cilAddressBook,
    cilAlbum,
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilArrowThickBottom,
    cilArrowThickFromLeft,
    cilArrowThickTop,
    cilAsterisk,
    cilAt,
    cilAudioDescription,
    cilAvTimer,
    cilBadge,
    cilBan,
    cilBank,
    cilBarChart,
    cilBarcode,
    cilBasket,
    cilBell,
    cilBolt,
    cilBook,
    cilBrowser,
    cilBullhorn,
    cilCalculator,
    cilCalendar,
    cilCaretBottom,
    cilCaretTop,
    cilCash,
    cilCasino,
    cilClone,
    cilCloudDownload,
    cilChart,
    cilChartPie,
    cilCheck,
    cilCheckAlt,
    cilChevronBottom,
    cilChevronDoubleRight,
    cilChevronTop,
    cilCheckCircle,
    cilClock,
    cilCloudy,
    cilCode,
    cilCog,
    cilCommentSquare,
    cilColumns,
    cilContact,
    cilCursor,
    cilDescription,
    cilDevices,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilExitToApp,
    cilExternalLink,
    cilGem,
    cilGlobeAlt,
    cilGrid,
    cilFaceDead,
    cilFile,
    cilFilter,
    cilFlagAlt,
    cilFont,
    cilHandPointUp,
    cilHandPointRight,
    cilHttps,
    cilInbox,
    cilInfo,
    cilInput,
    cilJustifyCenter,
    cilLan,
    cilLaptop,
    cilLayers,
    cilLifeRing,
    cilLightbulb,
    cilLink,
    cilLinkAlt,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilLockUnlocked,
    cilLoop,
    cilLoopCircular,
    cilLowVision,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMinus,
    cilMobile,
    cilMobileLandscape,
    cilMoney,
    cilMoon,
    cilNewspaper,
    cilNotes,
    cilObjectGroup,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPlus,
    cilPuzzle,
    cilScreenSmartphone,
    cilSettings,
    cilShare,
    cilShieldAlt,
    cilShortText,
    cilSitemap,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilStream,
    cilSync,
    cilTag,
    cilTags,
    cilTask,
    cilThumbUp,
    cilThumbDown,
    cilTransfer,
    cilTrash,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserPlus,
    cilUserUnfollow,
    cilUserX,
    cilWallet,
    cilWindow,
    cilX,
    cilXCircle,
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibGoogle,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibCcStripe,
    cibCcPaypal,
    cibCcApplePay,
    cibCcAmex,
  },
)
