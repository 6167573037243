import axiosAPI from '@/api/axios'

export default {
  async fetchCountries() {
    return await axiosAPI.get('/api/countries')
  },

  async fetchActiveCountries() {
    return await axiosAPI.get('/api/countries/active')
  },

  async changeConnection(payload) {
    return await axiosAPI.post('/api/countries/connect', payload)
  },

  async addCountry(payload) {
    return await axiosAPI.post('/api/countries', payload)
  },

  async updateCountry(id, payload) {
    return await axiosAPI.post(`/api/countries/${id}`, payload)
  },

  async updateStatus({ id, status }) {
    return await axiosAPI.post(`/api/countries/${id}/status`, {
      status: status,
    })
  },

  async deleteCountry(id) {
    return await axiosAPI.delete(`/api/countries/${id}`)
  },
}
