import axiosAPI from '@/api/axios'

export default {
  // Receive Endpoints
  async getSignature() {
    return await axiosAPI.get('/api/conversions/signature')
  },

  async updateSignature() {
    return await axiosAPI.post('/api/conversions/signature')
  },

  async generateReceiveUrl(payload) {
    return await axiosAPI.get('/api/conversions/receive', {
      params: {
        click_uuid: payload.click_uuid,
        external_id: payload.external_id,
      },
    })
  },

  async generateReceiveApiUrl(payload) {
    return await axiosAPI.get('/api/conversions/receive/api', {
      params: {
        external_id: payload.external_id,
        api_lead_id: payload.api_lead_id,
        offer_name: payload.offer_name,
        revenue: payload.revenue,
      },
    })
  },

  // Conversion Reports
  async fetchConversionGroupReports(payload) {
    return await axiosAPI.get('/api/conversions/group/report', {
      params: {
        start: payload.start,
        end: payload.end,
        show_only_conversions: payload?.show_only_conversions,
      },
    })
  },

  async fetchConversionApiGroupReports(payload) {
    const groupKey = payload?.group_key || 'Casheddy'
    return await axiosAPI.get('/api/conversions/group/api/report', {
      params: {
        ...payload,
        start: payload.start,
        end: payload.end,
        utm_parameters: {
          utm_source: payload?.utm_parameters?.includes('utm_source'),
          utm_medium: payload?.utm_parameters?.includes('utm_medium'),
          utm_campaign: payload?.utm_parameters?.includes('utm_campaign'),
        },
        [groupKey]: payload?.page || 1,
        [`${groupKey}-params`]: {
          ...payload?.[groupKey],
          offers: payload?.[groupKey]?.offers || [],
          utm_source: payload?.[groupKey]?.utm_source || [],
          utm_medium: payload?.[groupKey]?.utm_medium || [],
          utm_campaign: payload?.[groupKey]?.utm_campaign || [],
        },
      },
    })
  },

  async fetchConversionCustomReports(payload) {
    let params = {
      ...payload,
      [payload.group_key]: payload?.page || 1,
      group_key: payload?.group_key,
      start: payload.start,
      end: payload.end,
      sort_keys: payload?.sort_keys,
    }

    payload?.sort_keys?.forEach((item) => {
      params[`${item}-params`] = {
        page: payload?.[item]?.page || 1,
        perPage: payload?.[item]?.perPage || 15,
      }

      if (item === payload?.group_key) {
        params[`${item}-params`] = {
          ...params[`${item}-params`],
          ...payload?.[item],
          offer_ids: payload?.[item]?.offer_ids
            ? payload?.[item]?.offer_ids?.map((item) => item.value)
            : null,
          affiliate_ids: payload?.[item]?.affiliate_ids
            ? payload?.[item]?.affiliate_ids?.map((item) => item.value)
            : null,
        }
      }
    })

    return await axiosAPI.get('/api/conversions/custom/report', {
      params: params,
    })
  },

  async fetchConversionReports(payload) {
    return await axiosAPI.get('/api/conversions/report', {
      params: {
        page: payload?.page,
        perPage: payload?.perPage,
        start: payload.start,
        end: payload.end,
        offer_ids: payload?.offer_ids,
        affiliate_ids: payload?.affiliate_ids,
      },
    })
  },

  async fetchApiConversionReports(payload) {
    return await axiosAPI.get('/api/conversions/api/report', {
      params: {
        page: payload?.page,
        perPage: payload?.perPage,
        start: payload.start,
        end: payload.end,
        external_id: payload?.external_id,
        api_lead_id: payload?.api_lead_id,
        offer_name: payload?.offer_name,
      },
    })
  },

  // Revenue Counter Configuration
  async fetchRevenueCounterConfig() {
    return await axiosAPI.get('/api/conversions/revenue/config')
  },

  async addRevenueCounterConfig(payload) {
    return await axiosAPI.post('/api/conversions/revenue/config', payload)
  },

  async updateRevenueCounterConfig(id, payload) {
    return await axiosAPI.post(`/api/conversions/revenue/config/${id}`, payload)
  },

  async deleteRevenueCounterConfig(id) {
    return await axiosAPI.delete(`/api/conversions/revenue/config/${id}`)
  },
}
