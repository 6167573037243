import axiosAPI from '@/api/axios'

export default {
  async login(payload) {
    await axiosAPI.get('/sanctum/csrf-cookie')
    return axiosAPI.post('/login', payload)
  },

  logout() {
    return axiosAPI.post('/logout')
  },
}
