import postback from '@/api/postback'
import helpers, { sortDataByColumn } from '@/utils/helpers'

const state = {
  list: [],
  existing: [],
  url: '',
  redirect_url: '',
  loading: false,
  error: null,
}

const mutations = {
  SET_LIST(state, list) {
    state.list = list
  },
  SET_URL(state, url) {
    state.url = url
  },
  SET_EXISTING(state, existing) {
    state.existing = existing
  },
  SET_REDIRECT_URL(state, redirect) {
    state.redirect_url = redirect
  },
  INSERT_LIST(state, postback) {
    state.list = [].concat(postback, state.list)
  },
  UPDATE_POSTBACK(state, postback) {
    const index = state.list.findIndex((item) => item.id === postback.id)
    state.list[index].postback = postback.postback
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
}

const actions = {
  handleSortPostbackList({ state }, { column, order }) {
    sortDataByColumn(state.list, column, order)
  },

  handleSortExistingUrls({ state }, { column, order }) {
    sortDataByColumn(state.existing, column, order)
  },

  async fetchPostbackList({ commit }) {
    commit('SET_LOADING', true)
    return await postback
      .fetchPostbackList()
      .then((response) => {
        commit('SET_LIST', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchExistingUrls({ commit }, params) {
    commit('SET_LOADING', true)
    return await postback
      .fetchExistingUrls(params)
      .then((response) => {
        commit('SET_EXISTING', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async generatePostback({ commit }, payload) {
    commit('SET_LOADING', true)
    return await postback
      .generatePostback(payload)
      .then((response) => {
        commit('SET_URL', response.data.data)
        commit('SET_LOADING', false)
        return response.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
        return error.response.data
      })
  },

  async updatePostback({ commit }, payload) {
    commit('SET_LOADING', true)
    return await postback
      .updatePostback(payload)
      .then((response) => {
        commit('SET_URL', response.data.data)
        commit('SET_LOADING', false)
        return response.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
        return error.response.data
      })
  },

  async updatePostbackUrl({ commit }, { id, payload }) {
    commit('SET_LOADING', true)
    return await postback
      .updatePostbackUrl(id, payload)
      .then((response) => {
        commit('UPDATE_POSTBACK', response.data.data)
        commit('SET_LOADING', false)
        return response.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
        return error.response.data
      })
  },

  async handler({ commit }, payload) {
    return await postback
      .handler(payload)
      .then((response) => {
        commit('SET_REDIRECT_URL', response.data.data)
        return response.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        if (error.response.data) {
          return error.response.data
        }
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
