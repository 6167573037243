import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'

import documentation from '@/casheddy/store/modules/documentation'
import forms from '@/casheddy/store/modules/forms'

export default createStore({
  modules: {
    documentation,
    forms,
  },
  plugins: [
    createPersistedState({
      paths: ['documentation.partner'],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 3, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
})
