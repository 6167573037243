<template>
  <CTableHead color="light">
    <CTableRow>
      <CTableHeaderCell
        v-for="column in columns"
        :key="column.key"
        :scope="column._props.scope"
        :colspan="!isEmpty ? column._props.colSpan : 1"
        :rowspan="!isEmpty ? column._props.rowSpan : 1"
        :width="column._props?.width || ''"
        :class="[
          { ['column-' + column.key]: true },
          { ['with-sorter']: withSorter },
        ]"
        @click="(e) => onClickHeader(e, column.key)"
      >
        <div class="header-cell">
          <div class="text">{{ column.label }}</div>
          <div class="icon" v-if="withSorter">
            <CIcon :icon="toggleIcon" size="sm" :key="toggler[column.key]" />
          </div>
        </div>
      </CTableHeaderCell>
    </CTableRow>
    <CTableRow v-if="withRowSpan">
      <CTableHeaderCell
        v-for="extra in extras"
        :key="extra.key"
        :scope="extra._props.scope"
        :width="extra._props?.width || ''"
        :class="'column-' + extra.key"
      >
        {{ extra.label }}
      </CTableHeaderCell>
    </CTableRow>
  </CTableHead>
</template>

<script>
import { ref, computed, onMounted } from 'vue'

export default {
  name: 'HeaderRowWithProps',

  props: {
    withSorter: {
      type: Boolean,
      default: false,
    },
    columns: {
      required: true,
    },
    extras: {
      required: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const toggler = ref([])
    const order = ref('ASC')

    const onClickHeader = (e, index) => {
      if (!props.withSorter) {
        e.stopPropagation()
        return
      }

      toggler.value[index] = !toggler.value[index]
      order.value = toggler.value[index] ? 'DESC' : 'ASC'

      emit('sort:column', { index, order: order.value })
    }

    const toggleIcon = computed(() => {
      return order.value == 'DESC' ? 'cil-chevron-bottom' : 'cil-chevron-top'
    })

    onMounted(() => {
      Object.entries(props.columns).forEach((col) => {
        toggler.value[col[0]] = true
      })
    })

    return { toggler, onClickHeader, toggleIcon }
  },

  methods: {
    withRowSpan() {
      return this.columns?.some((col) => col._props.rowSpan > 1)
    },
  },

  emits: ['sort:column'],
}
</script>
