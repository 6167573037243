import axiosAPI from '@/api/axios'

export default {
  async fetchAffiliates() {
    return await axiosAPI.get('/api/affiliates')
  },

  async addAffiliate(payload) {
    return await axiosAPI.post('/api/affiliates', payload)
  },

  async updateAffiliate(id, payload) {
    return await axiosAPI.post(`/api/affiliates/${id}`, payload)
  },

  async deleteAffiliate(id) {
    return await axiosAPI.delete(`/api/affiliates/${id}`)
  },
}
