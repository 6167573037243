import axiosAPI from '@/api/axios'

export default {
  async fetchOptions() {
    return await axiosAPI.get('/api/options')
  },

  async updateOptions(payload) {
    return await axiosAPI.post('/api/options', payload)
  },

  async createCustomReport(payload) {
    return await axiosAPI.post('/api/options/reports', payload)
  },
}
