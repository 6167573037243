const state = {
  toasts: [],
}

const mutations = {
  INSERT_TOAST(state, toast) {
    state.toasts.push(toast)
  },
  CLEAR_TOASTS(state) {
    state.toasts = []
  },
}

const actions = {
  insertToast({ commit }, toast) {
    commit('INSERT_TOAST', toast)
  },
  clearToasts({ commit }) {
    commit('CLEAR_TOASTS')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
