import axiosAPI from '@/api/axios'

export default {
  async login(payload) {
    await axiosAPI.get('/sanctum/csrf-cookie')
    return axiosAPI.post('/skiptraze/login', payload)
  },

  logout() {
    return axiosAPI.post('/skiptraze/logout')
  },

  async fetchDashboardCounter() {
    return axiosAPI.get('/skiptraze/dashboard/counter')
  },

  async fetchDashboardHistory() {
    return axiosAPI.get('/skiptraze/dashboard/history')
  },

  async fetchRequests(payload) {
    return axiosAPI.get('/skiptraze/requests', {
      params: {
        page: payload?.page,
        perPage: payload?.perPage,
        start: payload.start,
        end: payload.end,
        filter: payload.filter,
        email: payload.email,
        mobile: payload.mobile,
        address: payload.address,
      },
    })
  },
}
