import statistics from '@/api/statistics'
import helpers, { sortDataByColumn } from '@/utils/helpers'

const state = {
  counter: [],
  leads: [],
  revenue: [],
  revenueLeads: [],
  status: [],
  apiStatus: [],
  lenders: [],
  sources: [],
  visits: [],
  dailyLeads: [],
  leadAcceptance: [],
  timeMonitoring: [],
  leadsMonitoring: [],
  revenueMonitoring: [],
  rplMonitoring: [],
  loading: false,
  otherLoading: false,
  error: null,
}

const mutations = {
  SET_COUNTER(state, counter) {
    state.counter = counter
  },
  SET_LEAD_STATISTICS(state, leads) {
    state.leads = leads
  },
  SET_REVENUE(state, revenue) {
    state.revenue = revenue
  },
  SET_REVENUE_LEADS(state, revenueLeads) {
    state.revenueLeads = revenueLeads
  },
  SET_DAILY_LEADS(state, dailyLeads) {
    state.dailyLeads = dailyLeads
  },
  SET_SOURCES(state, sources) {
    state.sources = sources
  },
  SET_LEAD_ACCEPTANCE(state, leadAcceptance) {
    state.leadAcceptance = leadAcceptance
  },
  SET_TIME_MONITORING(state, timeMonitoring) {
    state.timeMonitoring = timeMonitoring
  },
  SET_LEADS_MONITORING(state, leadsMonitoring) {
    state.leadsMonitoring = leadsMonitoring
  },
  SET_REVENUE_MONITORING(state, revenueMonitoring) {
    state.revenueMonitoring = revenueMonitoring
  },
  SET_RPL_MONITORING(state, rplMonitoring) {
    state.rplMonitoring = rplMonitoring
  },
  SET_STATUS(state, status) {
    state.status = status
  },
  SET_API_STATUS(state, apiStatus) {
    state.apiStatus = apiStatus
  },
  SET_API_LENDERS(state, lenders) {
    state.lenders = lenders
  },
  SET_VISITS(state, visits) {
    state.visits = visits
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_OTHER_LOADING(state, loading) {
    state.otherLoading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
}

const actions = {
  handleSortApiStatus({ state }, { column, order }) {
    sortDataByColumn(state.apiStatus, column, order)
  },

  handleSortLeadAcceptance({ state }, { column, order }) {
    let acceptanceData = state.leadAcceptance?.data
    sortDataByColumn(acceptanceData, column, order)
  },

  async fetchCounter({ commit }) {
    commit('SET_LOADING', true)
    return await statistics
      .fetchCounter()
      .then((response) => {
        commit('SET_COUNTER', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchLeadStatistics({ commit }, payload) {
    commit('SET_LOADING', true)
    return await statistics
      .fetchLeadStatistics(payload)
      .then((response) => {
        commit('SET_LEAD_STATISTICS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchRevenue({ commit }, payload) {
    if (typeof payload?.show_daily != 'undefined') {
      commit('SET_LOADING', true)
    } else {
      commit('SET_LOADING', true)
      commit('SET_OTHER_LOADING', true)
    }

    commit('SET_LOADING', true)
    return await statistics
      .fetchRevenue(payload)
      .then((response) => {
        commit('SET_REVENUE', response.data.data)
        commit('SET_LOADING', false)
        commit('SET_OTHER_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
        commit('SET_OTHER_LOADING', false)
      })
  },

  async fetchRevenueLeads({ commit }, payload) {
    if (payload?.page) {
      commit('SET_OTHER_LOADING', true)
    } else {
      commit('SET_LOADING', true)
      commit('SET_OTHER_LOADING', true)
    }

    return await statistics
      .fetchRevenueLeads(payload)
      .then((response) => {
        commit('SET_REVENUE_LEADS', response.data.data)
        commit('SET_LOADING', false)
        commit('SET_OTHER_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
        commit('SET_OTHER_LOADING', false)
      })
  },

  async fetchDailyLeads({ commit }, payload) {
    commit('SET_LOADING', true)
    return await statistics
      .fetchDailyLeads(payload)
      .then((response) => {
        commit('SET_DAILY_LEADS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchLeadAcceptance({ commit }, payload) {
    commit('SET_LOADING', true)
    return await statistics
      .fetchLeadAcceptance(payload)
      .then((response) => {
        commit('SET_SOURCES', response.data.sources)
        commit('SET_LEAD_ACCEPTANCE', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchTimeMonitoring({ commit }) {
    commit('SET_LOADING', true)
    return await statistics
      .fetchTimeMonitoring()
      .then((response) => {
        commit('SET_TIME_MONITORING', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchLeadsMonitoring({ commit }, payload) {
    commit('SET_LOADING', true)
    return await statistics
      .fetchLeadsMonitoring(payload)
      .then((response) => {
        commit('SET_LEADS_MONITORING', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchRevenueMonitoring({ commit }, payload) {
    commit('SET_LOADING', true)
    return await statistics
      .fetchRevenueMonitoring(payload)
      .then((response) => {
        commit('SET_REVENUE_MONITORING', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchRplMonitoring({ commit }, payload) {
    commit('SET_LOADING', true)
    return await statistics
      .fetchRplMonitoring(payload)
      .then((response) => {
        commit('SET_RPL_MONITORING', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchStatus({ commit }, payload) {
    commit('SET_LOADING', true)
    return await statistics
      .fetchStatus(payload)
      .then((response) => {
        commit('SET_STATUS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchApiStatus({ commit }, payload) {
    commit('SET_LOADING', true)
    return await statistics
      .fetchApiStatus(payload)
      .then((response) => {
        commit('SET_API_STATUS', response.data.data)
        commit('SET_API_LENDERS', response.data.lenders)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchTrackVisits({ commit }, payload) {
    commit('SET_LOADING', true)
    return await statistics
      .fetchTrackVisits(payload)
      .then((response) => {
        commit('SET_VISITS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
