export const infos = [
  {
    key: 'Version',
    value: '1.0.0',
  },
  {
    key: 'Last Updated',
    value: 'July 10, 2023',
  },
]

export const items = [
  {
    name: 'Authorization',
    isSlideMenu: false,
    isActive: false,
    to: '/v1/authorization',
  },
  {
    name: 'Endpoints',
    isSlideMenu: true,
    isActive: false,
    to: '/v1/endpoints',
    children: [
      {
        name: 'GET Request',
        isSlideMenu: false,
        isActive: false,
        to: '/v1/endpoints/get/request',
      },
      {
        name: 'POST Requests',
        isSlideMenu: false,
        isActive: false,
        to: '/v1/endpoints/post/requests',
      },
    ],
  },
  {
    name: 'Errors',
    isSlideMenu: false,
    isActive: false,
    to: '/v1/errors',
  },
]
