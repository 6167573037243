import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

import AdminLayout from '@/layouts/AdminLayout.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/login',
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: () =>
      import(/* webpackChunkName: "redirect" */ '@/views/Redirect.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { guestOnly: true },
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/admin/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { authOnly: true },
    component: () =>
      import(/* webpackChunkName: "logout" */ '@/views/admin/Logout.vue'),
  },
  {
    path: '/dashboard',
    name: '',
    component: AdminLayout,
    meta: { authOnly: true },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/admin/Dashboard.vue'
          ),
      },
    ],
  },
  {
    path: '/domains',
    name: '',
    component: AdminLayout,
    meta: { authOnly: true },
    children: [
      {
        path: '/domains',
        name: 'Domains',
        meta: { authOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/admin/Domains.vue'
          ),
      },
    ],
  },
  {
    path: '/applications',
    name: 'Applications',
    component: AdminLayout,
    meta: { authOnly: true },
    children: [
      {
        path: '/applications/pingtree',
        name: 'API Tree',
        meta: { authOnly: true },
        component: () => import('@/views/admin/applications/Pingtree.vue'),
      },
      {
        path: '/applications/fields',
        name: 'Fields',
        meta: { authOnly: true },
        component: () => import('@/views/admin/applications/Fields.vue'),
      },
      {
        path: '/applications/wall-of-offers',
        name: 'Wall of Offers',
        meta: { authOnly: true },
        component: () => import('@/views/admin/applications/WallOfOffers.vue'),
      },
    ],
  },
  {
    path: '/leads',
    name: 'Leads',
    component: AdminLayout,
    redirect: '/leads/requests',
    meta: { authOnly: true },
    children: [
      {
        path: '/leads/requests',
        name: 'Requests Logs',
        meta: { authOnly: true },
        component: () => import('@/views/admin/leads/RequestLogs.vue'),
      },
      {
        path: '/leads/status',
        name: 'Lead Status',
        meta: { authOnly: true },
        component: () => import('@/views/admin/leads/Status.vue'),
      },
    ],
  },
  {
    path: '/handlers',
    name: 'Handlers',
    component: AdminLayout,
    redirect: '/handlers/affiliates',
    meta: { authOnly: true },
    children: [
      {
        path: '/handlers/affiliates',
        name: 'Affiliates',
        meta: { authOnly: true },
        component: () => import('@/views/admin/handlers/Affiliates.vue'),
      },
      {
        path: '/handlers/offers',
        name: 'Offers',
        meta: { authOnly: true },
        component: () => import('@/views/admin/handlers/Offers.vue'),
      },
      {
        path: '/handlers/postbacks',
        name: 'Postback URLs',
        meta: { authOnly: true },
        component: () => import('@/views/admin/handlers/Postback.vue'),
      },
      {
        path: '/handlers/existing-urls',
        name: 'Existing URLs',
        meta: { authOnly: true },
        component: () => import('@/views/admin/handlers/ExistingUrls.vue'),
      },
      {
        path: '/handlers/urlbuilder',
        name: 'URL Builder',
        meta: { authOnly: true },
        component: () => import('@/views/admin/handlers/UrlBuilder.vue'),
      },
    ],
  },
  {
    path: '/conversions',
    name: 'Conversions',
    component: AdminLayout,
    redirect: '/conversions/report',
    meta: { authOnly: true },
    children: [
      {
        path: '/conversions/report',
        name: 'Conversion Report',
        meta: { authOnly: true },
        component: () => import('@/views/admin/conversions/Report.vue'),
      },
      {
        path: '/conversions/group-reports',
        name: 'Group Reports',
        meta: { authOnly: true },
        component: () => import('@/views/admin/conversions/GroupReport.vue'),
      },
      {
        path: '/conversions/custom-group-reports',
        name: 'Custom Group Reports',
        meta: { authOnly: true },
        component: () => import('@/views/admin/conversions/CustomReports.vue'),
      },
      {
        path: '/conversions/receive',
        name: 'Endpoint Builder',
        meta: { authOnly: true },
        component: () => import('@/views/admin/conversions/Receive.vue'),
      },
      {
        path: '/conversions/revenue-counter',
        name: 'Revenue Counter Configuration',
        meta: { authOnly: true },
        component: () => import('@/views/admin/conversions/RevenueCounter.vue'),
      },
      {
        path: '/conversions/click-logs',
        name: 'Click Logs',
        meta: { authOnly: true },
        component: () => import('@/views/admin/conversions/ClickLogs.vue'),
      },
    ],
  },
  {
    path: '/conversions/api',
    name: 'API Conversions',
    component: AdminLayout,
    redirect: '/conversions/api/report',
    meta: { authOnly: true },
    children: [
      {
        path: '/conversions/api/report',
        name: 'API Conversion Report',
        meta: { authOnly: true },
        component: () => import('@/views/admin/conversions/ApiReport.vue'),
      },
      {
        path: '/conversions/api/group-reports',
        name: 'API Group Reports',
        meta: { authOnly: true },
        component: () =>
          import('@/views/admin/conversions/ApiGroupReports.vue'),
      },
      {
        path: '/conversions/api/receive',
        name: 'API Endpoint Builder',
        meta: { authOnly: true },
        component: () => import('@/views/admin/conversions/ReceiveApi.vue'),
      },
    ],
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: AdminLayout,
    redirect: '/statistics/leads/daily',
    meta: { authOnly: true },
    children: [
      {
        path: '/statistics/leads/daily',
        name: 'Daily Leads',
        meta: { authOnly: true },
        component: () => import('@/views/admin/statistics/DailyLeads.vue'),
      },
      {
        path: '/statistics/api/status',
        name: 'API Status Report',
        meta: { authOnly: true },
        component: () => import('@/views/admin/statistics/ApiStatus.vue'),
      },
      {
        path: '/statistics/leads/acceptance',
        name: 'Lead Acceptance',
        meta: { authOnly: true },
        component: () => import('@/views/admin/statistics/LeadAcceptance.vue'),
      },
      {
        path: '/statistics/monitoring/time',
        name: 'Time Monitoring',
        meta: { authOnly: true },
        component: () => import('@/views/admin/statistics/TimeMonitoring.vue'),
      },
      {
        path: '/statistics/monitoring/leads',
        name: 'Leads Monitoring',
        meta: { authOnly: true },
        component: () => import('@/views/admin/statistics/LeadsMonitoring.vue'),
      },
      {
        path: '/statistics/monitoring/revenue',
        name: 'Revenue Monitoring',
        meta: { authOnly: true },
        component: () =>
          import('@/views/admin/statistics/RevenueMonitoring.vue'),
      },
      {
        path: '/statistics/monitoring/rpl',
        name: 'RPL Monitoring',
        meta: { authOnly: true },
        component: () => import('@/views/admin/statistics/RplMonitoring.vue'),
      },
    ],
  },
  {
    path: '/sms-center',
    name: 'SMS Center',
    component: AdminLayout,
    redirect: '/sms-center/sms-campaigns',
    meta: { authOnly: true },
    children: [
      {
        path: '/sms-center/sms-campaigns',
        name: 'SMS Campaigns',
        meta: { authOnly: true },
        component: () => import('@/views/admin/sms-center/SmsCampaigns.vue'),
      },
      {
        path: '/sms-center/history-logs',
        name: 'Revenue',
        meta: { authOnly: true },
        component: () => import('@/views/admin/sms-center/HistoryLogs.vue'),
      },
      {
        path: '/sms-center/tracking-logs',
        name: 'Tracking Logs',
        meta: { authOnly: true },
        component: () => import('@/views/admin/sms-center/TrackVisits.vue'),
      },
      {
        path: '/sms-center/sms-settings',
        name: 'SMS Settings',
        meta: { authOnly: true },
        component: () => import('@/views/admin/sms-center/SmsSettings.vue'),
      },
    ],
  },
  {
    path: '/partners',
    name: 'Partners',
    component: AdminLayout,
    redirect: '/partners/registration',
    meta: { authOnly: true },
    children: [
      {
        path: '/partners/registration',
        name: 'Registration',
        meta: { authOnly: true },
        component: () => import('@/views/admin/partners/Registration.vue'),
      },
      {
        path: '/partners/revenue',
        name: 'Lead Revenue',
        meta: { authOnly: true },
        component: () => import('@/views/admin/partners/Revenue.vue'),
      },
      {
        path: '/partners/lead-statistics',
        name: 'Lead Payouts',
        meta: { authOnly: true },
        component: () => import('@/views/admin/partners/LeadStatistics.vue'),
      },
    ],
  },
  {
    path: '/settings',
    name: 'Settings',
    component: AdminLayout,
    redirect: '/settings/accounts',
    meta: { authOnly: true },
    children: [
      {
        path: '/settings/countries',
        name: 'Countries',
        meta: { authOnly: true },
        component: () => import('@/views/admin/settings/Countries.vue'),
      },
      {
        path: '/settings/accounts',
        name: 'Accounts',
        meta: { authOnly: true },
        component: () => import('@/views/admin/settings/Accounts.vue'),
      },
    ],
  },
  {
    path: '/500',
    name: 'Server Error',
    component: () =>
      import(/* webpackChunkName: "500" */ '@/views/errors/500.vue'),
    meta: { authOnly: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page Not Found',
    component: () =>
      import(/* webpackChunkName: "404" */ '@/views/errors/404.vue'),
    meta: { authOnly: true },
  },
]

const router = createRouter({
  history: createWebHashHistory('/'),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  // reset states
  store.dispatch('forms/updateForm', {})
  if (from.name !== to.name) {
    store.commit('auth/CLEAR_CANCEL_TOKENS', from.name)
  }

  // store.dispatch('auth/setRoute', to)
  // store.dispatch('countries/updateRenderKey')

  // auth route
  if (to.matched.some((record) => record.meta.authOnly)) {
    // if user is not logged in
    if (!store.state.auth.loggedIn || !store.state.auth.user?.is_admin) {
      store.dispatch('forms/updateForm', {})
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
      return
    }
  }

  // guest route
  if (to.matched.some((record) => record.meta.guestOnly)) {
    // if user is authenticated
    if (store.state.auth.loggedIn && store.state.auth.user?.is_admin) {
      store.dispatch('forms/updateForm', { show_daily: false })
      next({
        path: '/dashboard',
        query: { redirect: to.fullPath },
      })
      return
    }
  }

  next()
  return
})

export default router
