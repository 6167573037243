const state = {
  validated: null,
  form: {},
  overrideShowModal: false,
  modalRef: { action: '', show: false },
}

const mutations = {
  SET_VALIDATED(state, value) {
    state.validated = value
  },
  UPDATE_FORM(state, form) {
    state.form = form

    if (Object.keys(form).length <= 0) {
      state.modalRef = { action: '', show: false }
    }
  },
  SET_OVERRIDE_SHOW_MODAL(state, value) {
    state.overrideShowModal = value
  },
  SET_MODAL_REF(state, value) {
    state.modalRef = value
  },
}

const actions = {
  updateFlag({ commit }, value) {
    commit('SET_VALIDATED', value)
  },
  updateForm({ commit }, form) {
    commit('UPDATE_FORM', form)
  },
  setOverrideShowModal({ commit }, value) {
    commit('SET_OVERRIDE_SHOW_MODAL', value)
  },
  setModalRef({ commit }, value) {
    commit('SET_MODAL_REF', value)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
