import pingtree from '@/api/pingtree'
import helpers from '@/utils/helpers'

const state = {
  api: [],
  sms_api: [],
  loading: false,
  error: null,
}

const mutations = {
  SET_API_PINGTREE(state, api) {
    state.api = api
  },
  SET_SMS_API_PINGTREE(state, sms_api) {
    state.sms_api = sms_api
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
}

const actions = {
  async fetchPingtree({ commit }, params) {
    commit('SET_LOADING', true)
    return await pingtree
      .fetchPingtree(params)
      .then((response) => {
        commit('SET_API_PINGTREE', response.data.data?.api || [])
        commit('SET_SMS_API_PINGTREE', response.data.data?.sms_api || [])
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async syncPingtree({ commit }, payload) {
    commit('SET_LOADING', true)
    return await pingtree
      .syncPingtree(payload)
      .then((response) => {
        commit('SET_API_PINGTREE', response.data.data?.api || [])
        commit('SET_SMS_API_PINGTREE', response.data.data?.sms_api || [])
        commit('SET_LOADING', false)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
