<template>
  <div class="brand">
    <div class="logo fineddy-logo">
      <CImage fluid :src="brand" alt="Fineddy logo" />
    </div>
    <div class="title">
      <span>{{ title }}</span>
    </div>
    <CButton
      id="button-menu-mobile"
      class="burger-menu-icon"
      @click="onShowMenu"
    >
      <SvgBurgerIcon />
    </CButton>
  </div>
</template>

<script>
import brand from '@/assets/brand/fineddy/logo.png'
import SvgBurgerIcon from '@/components/others/SvgBurgerIcon.vue'

export default {
  name: 'SidebarBrand',

  components: { SvgBurgerIcon },

  setup() {
    return {
      brand,
    }
  },

  props: {
    onShowMenu: Function,
  },

  data() {
    return {
      title: 'API Documentation',
    }
  },
}
</script>
