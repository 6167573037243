import axiosAPI from '@/api/axios'

export default {
  async fetchAccounts() {
    return await axiosAPI.get('/api/accounts')
  },

  async addAccount(payload) {
    return await axiosAPI.post('/api/accounts/', payload)
  },

  async updateAccount(id, payload) {
    return await axiosAPI.post(`/api/accounts/${id}`, payload)
  },

  async resetPassword(id, payload) {
    return await axiosAPI.post(`/api/accounts/${id}/password/reset`, payload)
  },

  async deleteAccount(id) {
    return await axiosAPI.delete(`/api/accounts/${id}`)
  },
}
