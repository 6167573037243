<template>
  <div class="sidebar" :class="className">
    <SidebarBrand :onShowMenu="onShowMenu" />
    <div class="mobile-menu-closer"></div>
    <SidebarMenuLayout :onShowMenu="onShowMenu" />
  </div>
</template>

<script>
import SidebarBrand from '@/skiptraze/views/integration/sidebar/Brand.vue'
import SidebarMenuLayout from '@/skiptraze/views/integration/sidebar/Menu/Layout.vue'

export default {
  name: 'SidebarLayout',

  components: {
    SidebarBrand,
    SidebarMenuLayout,
  },

  data() {
    return {
      className: '',
    }
  },

  methods: {
    onShowMenu(event) {
      event?.preventDefault && event.preventDefault()
      this.className = this.className == '' ? 'menu-opened' : ''
    },
  },
}
</script>
