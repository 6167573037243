import logs from '@/api/logs'
import helpers, { sortDataByColumn } from '@/utils/helpers'

const state = {
  counter: [],
  history: [],
  clicks: [],
  conversions: [],
  apiRequests: [],
  loading: false,
  history_loading: false,
  error: null,
}

const mutations = {
  SET_COUNTER(state, counter) {
    state.counter = counter
  },
  SET_HISTORY(state, list) {
    state.history = list
  },
  UPDATE_HISTORY(state, list) {
    state.history = {
      ...list,
      data: [].concat(state.history.data, list.data),
    }
  },
  SET_CLICKS(state, clicks) {
    state.clicks = clicks
  },
  SET_CONVERSIONS(state, conversions) {
    state.conversions = conversions
  },
  SET_API_REQUESTS(state, requests) {
    state.apiRequests = requests
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_HISTORY_LOADING(state, loading) {
    state.history_loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
}

const actions = {
  handleSortClicks({ state }, { column, order }) {
    sortDataByColumn(state.clicks?.data, column, order)
  },

  async fetchHistoryCounter({ commit }) {
    commit('SET_LOADING', true)
    return await logs
      .fetchHistoryCounter()
      .then((response) => {
        commit('SET_COUNTER', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchHistory({ commit }, params) {
    commit('SET_HISTORY_LOADING', true)
    return await logs
      .fetchHistory(params)
      .then((response) => {
        commit('SET_HISTORY_LOADING', false)
        if (params?.page > 1) {
          commit('UPDATE_HISTORY', response.data.data)
        } else {
          commit('SET_HISTORY', response.data.data)
        }
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_HISTORY_LOADING', false)
      })
  },

  async fetchClicks({ commit }, params) {
    commit('SET_LOADING', true)
    return await logs
      .fetchClicks(params)
      .then((response) => {
        commit('SET_CLICKS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchConversions({ commit }, params) {
    commit('SET_LOADING', true)
    return await logs
      .fetchConversions(params)
      .then((response) => {
        commit('SET_CONVERSIONS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchApiRequests({ commit }, params) {
    commit('SET_LOADING', true)
    return await logs
      .fetchApiRequests(params)
      .then((response) => {
        commit('SET_API_REQUESTS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
