<template>
  <div class="content-menu">
    <div class="content-infos">
      <div class="info">
        <b>Skiptraze Integration</b>
      </div>
      <div class="info" v-for="(info, index) in infos" :key="index">
        <b>{{ info.key }}:</b> {{ info.value }}
      </div>
    </div>
    <SidebarMenu :items="list || items" class="pt-3" :onShowMenu="onShowMenu" />
  </div>
</template>

<script>
import storeSkiptraze from '@/skiptraze/store'
import routerSkiptraze from '@/skiptraze/router'
import { useRoute } from 'vue-router'
import { reactive, onMounted, ref } from 'vue'
import { infos, items } from '@/skiptraze/views/integration/assets/sidebar'
import SidebarMenu from '@/skiptraze/views/integration/sidebar/Menu/List.vue'

export default {
  name: 'SidebarMenuLayout',

  components: {
    SidebarMenu,
  },

  setup() {
    const partner = reactive(storeSkiptraze.state.documentation?.partner)
    const route = useRoute()
    const list = ref()

    const normalizePath = (path) => {
      return decodeURI(path)
        .replace(/#.*$/, '')
        .replace(/(index)?\.(html)$/, '')
    }

    const isItemActive = (item) => {
      if (item.to === undefined) {
        return false
      }

      if (route.hash === item.to) {
        return true
      }

      const currentPath = normalizePath(route.path)
      const targetPath = normalizePath(item.to)

      return currentPath === targetPath
    }

    const getList = (items) => {
      return items?.map((item) => {
        if (item.children?.length > 0) {
          const children = getList(item.children)

          if (partner && Boolean(partner.send_partial_lead)) {
            const newChild = {
              name: 'Send partial lead',
              isSlideMenu: false,
              isActive: false,
              to: '/v1/endpoints/send-partial-lead',
            }

            children.push({
              ...newChild,
              isActive: isItemActive(newChild),
            })
          }

          return {
            ...item,
            children,
          }
        }

        return {
          ...item,
          isActive: isItemActive(item),
        }
      })
    }

    routerSkiptraze.afterEach(() => {
      list.value = getList(items)
    })

    onMounted(() => {
      list.value = getList(items)
    })

    return {
      list,
    }
  },

  props: {
    onShowMenu: Function,
  },

  data() {
    return {
      infos: infos,
      items: items,
      affiliate: 'Affiliate ID',
    }
  },

  computed: {
    username() {
      return storeSkiptraze.state.documentation?.partner?.username || '-'
    },
  },
}
</script>
