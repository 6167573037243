import auth from '@/api/auth'
import router from '@/router'
import helpers from '@/utils/helpers'
import Bugsnag from '@bugsnag/js'

const state = {
  user: null,
  loggedIn: false,
  loading: false,
  error: null,
  csrf: null,
  cancelTokens: [],
}

const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_LOGGED_IN(state, value) {
    state.loggedIn = value
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_CSRF(state, value) {
    state.csrf = value
    localStorage.setItem('access_token', value)
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  ADD_CANCEL_TOKEN(state, { key, source }) {
    if (!state.cancelTokens[key]) {
      state.cancelTokens[key] = []
    }
    state.cancelTokens[key].push(source)
  },
  CLEAR_CANCEL_TOKENS(state, key) {
    if (state.cancelTokens[key]) {
      state.cancelTokens[key].forEach((source) => source.cancel('Canceled'))
      state.cancelTokens[key] = []
    }
  },
}

const actions = {
  async login({ commit }, form) {
    commit('SET_LOADING', true)

    return await auth
      .login(form)
      .then((response) => {
        const user = response.data.data
        Bugsnag.setUser(user.id, user.email, user.name)

        commit('SET_LOGGED_IN', true)
        commit('SET_USER', user)
        commit('SET_CSRF', user.token)

        setTimeout(() => {
          commit('SET_LOADING', false)
          router.push({ path: '/dashboard' })
        }, 1000)

        return response
      })
      .catch((error) => {
        router.push({ path: '/login' })
        commit('SET_LOADING', false)
        commit('SET_USER', null)
        commit('SET_ERROR', helpers.getError(error))
        return error.response
      })
  },

  async logout({ commit }) {
    commit('SET_LOADING', true)

    return await auth
      .logout()
      .then((response) => {
        if (router.currentRoute.name !== 'login') {
          commit('SET_LOGGED_IN', false)
          commit('SET_USER', null)
          commit('SET_CSRF', response.data.data.token)

          setTimeout(() => {
            commit('SET_LOADING', false)
            router.push({ path: '/login' })
          }, 500)
        }
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', helpers.getError(error))
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
