import conversion from '@/api/conversion'
import helpers, { sortDataByColumn } from '@/utils/helpers'

const state = {
  receive_url: '',
  receive_api_url: '',
  signature: '',
  loading: false,
  error: null,
  reports: [],
  api_reports: [],
  api_group_reports: [],
  group_reports: [],
  custom_reports: [],
  custom_report_sort: [],
  custom_loading: {},
  revenue_configs: [],
}

const mutations = {
  SET_SIGNATURE(state, signature) {
    state.signature = signature
  },
  SET_RECEIVE_URL(state, url) {
    state.receive_url = url
  },
  SET_RECEIVE_API_URL(state, url) {
    state.receive_api_url = url
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  SET_REPORTS(state, reports) {
    state.reports = reports
  },
  SET_API_REPORTS(state, data) {
    state.api_reports = data
  },
  SET_GROUP_REPORTS(state, reports) {
    state.group_reports = reports
  },
  SET_API_GROUP_REPORTS(state, reports) {
    state.api_group_reports = reports
  },
  SET_CUSTOM_REPORTS(state, reports) {
    state.custom_reports = reports
  },
  SET_CUSTOM_REPORTS_SORT(state, sort) {
    state.custom_report_sort = sort
  },
  SET_CUSTOM_LOADING(state, loading) {
    state.custom_loading = loading
  },
  SET_REVENUE_CONFIGS(state, configs) {
    state.revenue_configs = configs
  },
  INSERT_REVENUE_CONFIG(state, config) {
    state.revenue_configs = [].concat(config, state.revenue_configs)
  },
  UPDATE_REVENUE_CONFIG(state, config) {
    const index = state.revenue_configs.findIndex(
      (item) => item.id === config.id,
    )
    state.revenue_configs[index] = config
  },
  REMOVE_REVENUE_CONFIG(state, id) {
    const index = state.revenue_configs.findIndex((item) => item.id === id)
    if (~index) state.revenue_configs.splice(index, 1)
  },
}

const actions = {
  handleSortReports({ state }, { column, order }) {
    sortDataByColumn(state.reports?.data?.data, column, order)
  },

  handleSortApiReports({ state }, { column, order }) {
    sortDataByColumn(state.api_reports?.data?.data, column, order)
  },

  handleSortGroupReports({ state }, { column, order }) {
    let conversions = state.group_reports?.conversions
    sortDataByColumn(conversions, column, order)
  },

  handleSortCustomReports({ state }, { column, order, key }) {
    let conversions = state.custom_reports?.data?.find((item) => {
      return item.report_name == key
    })?.items?.data

    sortDataByColumn(conversions, column, order)
  },

  handleSortApiGroupReport({ state }, { column, order, key }) {
    let conversions = state.api_group_reports?.data?.find((item) => {
      return item.table_name == key
    })?.items?.data

    sortDataByColumn(conversions, column, order)
  },

  handleRearrangeCustomReports({ commit, state }, { index, action }) {
    let data = state.custom_reports?.data
    let newIdx = action == 'top' ? index - 1 : index + 1
    data[newIdx] = data.splice(index, 1, data[newIdx])[0]

    const keys = data?.map((item) => item.report_name)
    commit('SET_CUSTOM_REPORTS_SORT', keys)
  },

  async fetchSignature({ commit }) {
    commit('SET_LOADING', true)
    return await conversion
      .getSignature()
      .then((response) => {
        commit('SET_SIGNATURE', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async updateSignature({ commit }) {
    commit('SET_LOADING', true)
    return await conversion
      .updateSignature()
      .then((response) => {
        commit('SET_SIGNATURE', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async generateReceiveUrl({ commit }, payload) {
    commit('SET_LOADING', true)
    return await conversion
      .generateReceiveUrl(payload)
      .then((response) => {
        commit('SET_RECEIVE_URL', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async generateReceiveApiUrl({ commit }, payload) {
    commit('SET_LOADING', true)
    return await conversion
      .generateReceiveApiUrl(payload)
      .then((response) => {
        commit('SET_RECEIVE_API_URL', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchConversionReports({ commit }, payload) {
    commit('SET_LOADING', true)
    return await conversion
      .fetchConversionReports(payload)
      .then((response) => {
        commit('SET_REPORTS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchApiConversionReports({ commit }, payload) {
    commit('SET_LOADING', true)
    return await conversion
      .fetchApiConversionReports(payload)
      .then((response) => {
        commit('SET_API_REPORTS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchConversionGroupReports({ commit }, payload) {
    commit('SET_LOADING', true)
    return await conversion
      .fetchConversionGroupReports(payload)
      .then((response) => {
        commit('SET_GROUP_REPORTS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async fetchConversionApiGroupReports({ commit }, payload) {
    commit('SET_LOADING', true)
    commit('SET_CUSTOM_LOADING', { [payload.group_key]: true })
    return await conversion
      .fetchConversionApiGroupReports(payload)
      .then((response) => {
        commit('SET_API_GROUP_REPORTS', response.data.data)
        commit('SET_LOADING', false)
        commit('SET_CUSTOM_LOADING', { [payload.group_key]: false })
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
        commit('SET_CUSTOM_LOADING', { [payload.group_key]: false })
      })
  },

  async fetchConversionCustomReports({ commit }, payload) {
    commit('SET_LOADING', true)
    commit('SET_CUSTOM_LOADING', { [payload.group_key]: true })
    return await conversion
      .fetchConversionCustomReports(payload)
      .then((response) => {
        const data = response.data.data
        const keys = data?.data?.map((item) => item.report_name)

        commit('SET_CUSTOM_REPORTS', data)
        commit('SET_CUSTOM_REPORTS_SORT', keys)
        commit('SET_LOADING', false)
        commit('SET_CUSTOM_LOADING', { [payload.group_key]: false })

        return response.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
        commit('SET_CUSTOM_LOADING', { [payload.group_key]: false })
      })
  },

  async fetchRevenueCounterConfig({ commit }) {
    commit('SET_LOADING', true)
    return await conversion
      .fetchRevenueCounterConfig()
      .then((response) => {
        commit('SET_REVENUE_CONFIGS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async addRevenueCounterConfig({ commit }, payload) {
    return await conversion
      .addRevenueCounterConfig(payload)
      .then((response) => {
        commit('INSERT_REVENUE_CONFIG', response.data.data)
        return response.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async updateRevenueCounterConfig({ commit }, { id, payload }) {
    return await conversion
      .updateRevenueCounterConfig(id, payload)
      .then((response) => {
        commit('UPDATE_REVENUE_CONFIG', response.data.data)
        return response.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async deleteRevenueCounterConfig({ commit }, id) {
    return await conversion
      .deleteRevenueCounterConfig(id)
      .then(() => {
        commit('REMOVE_REVENUE_CONFIG', id)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
