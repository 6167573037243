import publishers from '@/api/publishers'
import routerPublisher from '@/publisher/router'
import helpers from '@/utils/helpers'

const state = {
  user: null,
  counter: [],
  history: [],
  leads: [],
  statistics: [],
  notification: null,
  loggedIn: false,
  loading: false,
  error: null,
}

const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_COUNTER(state, counter) {
    state.counter = counter
  },
  SET_HISTORY(state, history) {
    state.history = history
  },
  SET_LEADS(state, leads) {
    state.leads = leads
  },
  SET_STATISTICS(state, statistics) {
    state.statistics = statistics
  },
  SET_NOTIFICATION(state, notification) {
    state.notification = notification
  },
  SET_LOGGED_IN(state, value) {
    state.loggedIn = value
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
}

const actions = {
  async login({ commit }, form) {
    commit('SET_LOADING', true)

    return await publishers
      .login(form)
      .then((response) => {
        commit('SET_LOGGED_IN', true)
        commit('SET_USER', response.data.data)

        setTimeout(() => {
          commit('SET_LOADING', false)
          routerPublisher.push({ path: '/dashboard' })
        }, 1000)

        return response
      })
      .catch((error) => {
        routerPublisher.push({ path: '/login' })
        commit('SET_LOADING', false)
        commit('SET_USER', null)
        commit('SET_ERROR', helpers.getError(error, 'publisher'))
        return error.response
      })
  },

  async logout({ commit }) {
    commit('SET_LOADING', true)

    return await publishers
      .logout()
      .then(() => {
        if (routerPublisher.currentRoute.name !== 'login') {
          commit('SET_LOGGED_IN', false)
          commit('SET_USER', null)

          setTimeout(() => {
            commit('SET_LOADING', false)
            routerPublisher.push({ path: '/login' })
          }, 500)
        }
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', helpers.getError(error, 'publisher'))
      })
  },

  async fetchDashboardCounter({ commit }, params) {
    commit('SET_LOADING', true)

    return await publishers
      .fetchDashboardCounter(params)
      .then((response) => {
        commit('SET_COUNTER', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', helpers.getError(error, 'publisher'))
      })
  },

  async fetchDashboardHistory({ commit }, params) {
    commit('SET_LOADING', true)

    return await publishers
      .fetchDashboardHistory(params)
      .then((response) => {
        commit('SET_HISTORY', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', helpers.getError(error, 'publisher'))
      })
  },

  async fetchLeads({ commit }, payload) {
    commit('SET_LOADING', true)

    return await publishers
      .fetchLeads(payload)
      .then((response) => {
        commit('SET_LEADS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', helpers.getError(error, 'publisher'))
      })
  },

  async fetchStatistics({ commit }, payload) {
    commit('SET_LOADING', true)

    return await publishers
      .fetchStatistics(payload)
      .then((response) => {
        commit('SET_STATISTICS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', helpers.getError(error, 'publisher'))
      })
  },

  async fetchNotificationSettings({ commit }) {
    commit('SET_LOADING', true)

    return await publishers
      .fetchNotificationSettings()
      .then((response) => {
        commit('SET_NOTIFICATION', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', helpers.getError(error, 'publisher'))
      })
  },

  async updateNotificationSettings({ commit }, payload) {
    commit('SET_LOADING', true)

    return await publishers
      .updateNotificationSettings(payload)
      .then((response) => {
        commit('SET_NOTIFICATION', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', helpers.getError(error, 'publisher'))
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
