<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="d-lg-none ps-1" @click="onToggleSidebar">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav class="d-none d-lg-flex me-auto fw-bolder header-nav">
        <CHeaderText>{{ title }}</CHeaderText>
        <div class="country-selector">
          <AppCountry />
        </div>
      </CHeaderNav>
      <CHeaderNav class="d-none d-lg-flex me-3" v-if="username !== ''">
        <CHeaderText class="d-flex">
          <span class="pe-2">{{ header }}</span>
          <div class="fw-bolder">{{ username }}</div>
        </CHeaderText>
      </CHeaderNav>
      <CHeaderBrand class="mx-auto d-none" to="/">
        <CImage fluid class="my-3" :src="logo" :width="120" alt="LOGO" />
      </CHeaderBrand>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import { ref } from 'vue'
import store from '@/store'
import logo from '@/assets/brand/fineddy/logo.png'
import AppBreadcrumb from '@/components/app/AppBreadcrumb.vue'
import AppCountry from '@/components/app/AppCountry.vue'

export default {
  name: 'AppHeader',

  components: {
    AppBreadcrumb,
    AppCountry,
  },

  props: {
    username: {
      type: String,
      default: '',
    },
  },

  setup() {
    const title = ref('Admin Panel v1')
    const header = ref('Logged in as')

    const onToggleSidebar = () => {
      store.dispatch('sidebar/toggleSidebar')
    }

    return {
      logo,
      title,
      header,
      onToggleSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>
.header-nav {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .country-selector {
    display: flex;
    height: 100%;
    align-items: center;
  }
}
</style>
