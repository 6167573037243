import domains from '@/api/domains'
import helpers from '@/utils/helpers'

const state = {
  domains: [],
  loading: false,
  error: null,
  apiKey: '',
}

const mutations = {
  SET_DOMAINS(state, domains) {
    state.domains = domains
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  SET_API_KEY(state, apiKey) {
    state.apiKey = apiKey
  },
  INSERT_DOMAIN(state, domain) {
    state.domains = [].concat(domain, state.domains)
  },
  UPDATE_DOMAIN(state, domain) {
    const index = state.domains.findIndex((item) => item.id === domain.id)
    state.domains[index] = domain
  },
  UPDATE_DOMAIN_API_KEY(state, domain) {
    const index = state.domains.findIndex((item) => item.id === domain.id)
    state.domains[index].api_key_encrypted = domain.api_key_encrypted
  },
  UPDATE_DOMAIN_STATUS(state, domain) {
    const index = state.domains.findIndex((item) => item.id === domain.id)
    state.domains[index].active = domain.active
  },
  REMOVE_DOMAIN(state, id) {
    const index = state.domains.findIndex((item) => item.id === id)
    if (~index) state.domains.splice(index, 1)
  },
}

const actions = {
  async decryptApiKey({ commit }, { data }) {
    return await helpers.decryptData(data).then((response) => {
      commit('SET_API_KEY', response)
      return response
    })
  },

  async fetchDomains({ commit }) {
    commit('SET_LOADING', true)
    return await domains
      .fetchDomains()
      .then((response) => {
        commit('SET_DOMAINS', response.data.data)
        commit('SET_LOADING', false)
        return response.data.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async addDomain({ commit }, payload) {
    return await domains
      .addDomain(payload)
      .then((response) => {
        commit('INSERT_DOMAIN', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async updateDomain({ commit }, { id, payload }) {
    return await domains
      .updateDomain(id, payload)
      .then((response) => {
        commit('UPDATE_DOMAIN', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async regenerateApiKey({ commit }, { id }) {
    return await domains
      .regenerateApiKey(id)
      .then((response) => {
        commit('UPDATE_DOMAIN_API_KEY', response.data.data)
        return response.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        return error.response.data
      })
  },

  async updateStatus({ commit }, { id }) {
    return await domains
      .updateStatus(id)
      .then((response) => {
        commit('UPDATE_DOMAIN_STATUS', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async deleteDomain({ commit }, id) {
    return await domains
      .deleteDomain(id)
      .then(() => {
        commit('REMOVE_DOMAIN', id)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
