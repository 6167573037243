<template>
  <svg
    class="rounded me-2"
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid slice"
    focusable="false"
    role="img"
  >
    <rect width="100%" height="100%" :class="status"></rect>
  </svg>
</template>

<script>
export default {
  name: 'SvgToastIcon',

  props: {
    status: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
svg > rect {
  &.success {
    fill: var(--cui-green);
  }

  &.error {
    fill: var(--cui-red);
  }

  &.warning {
    fill: var(--cui-orange);
  }
}
</style>
