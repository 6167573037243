<template>
  <CTableBody>
    <CTableRow
      v-for="row in rows"
      :key="'row-' + row"
      class="table-skeleton-row"
    >
      <CTableDataCell v-for="column in columns" :key="'row-item-' + column">
        <div
          class="table-skeleton"
          :style="`width: ${Math.floor(Math.random() * 51) + 50}%;`"
        ></div>
      </CTableDataCell>
    </CTableRow>
  </CTableBody>
</template>

<script>
export default {
  name: 'TableSkeleton',

  props: {
    columns: {
      required: true,
    },
    rows: {
      type: Number,
      default: 15,
    },
  },
}
</script>
